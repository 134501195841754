import React, { useState, useContext, useEffect } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import Guide from 'components/Guide';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import Loading from 'components/Loading';
import moment from 'moment';
import { numberFormatPipe } from 'pipes';
import GoogleOAuthRequest from './GoogleOAuthRequest';
import VisitCount from './VisitCount';


const DashboardPage = () => {
    const { showSnackbar } = useContext(AppContext);

    // statistics
    const [ statistics, setStatistics ] = useState();
    const [ yearmonth, setYearmonth ] = useState(moment().format('YYYY-MM'));
    useEffect(() => {
        const date = moment(yearmonth, 'YYYY-MM');
        const params = { year: date.format('YYYY'), month: date.format('MM')};
        API.get('/admin/statistics', { params })
        .then(setStatistics);
    }, [ yearmonth ]);
    // end: statistics


    const handleUpdateKakaoGroupFile = () => {
        const generateDate = moment().format('YYYY-MM-DD');
        API.put('/membership/update-kakao-group-file', { generateDate })
        .then(() => {
            showSnackbar('업데이트 했습니다. 카카오 알림을 보내세요');
        });
    }

    if (!statistics) return (<Loading />)

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>통계</PageTitle>

                <input type="month" 
                    value={yearmonth}
                    onChange={e => {
                        setYearmonth(e.target.value)
                    }}
                />

                <div>
                    <Typography variant='body1'>{`월 주문량: ${statistics.monthlyOrderCount} 건`}</Typography>
                    <Typography variant='body1'>{`월 매출: ${numberFormatPipe(statistics.monthlyAmount)} 원`}</Typography>
                </div>

                
            </Paper>

            <Paper className="pagePadding" style={{ marginTop: '24px' }}>
                <PageTitle>카카오그룹 파일 업데이트</PageTitle>

                <div style={{ marginTop: '24px' }}>
                    <Guide>"카카오채널알림 동의한 멤버십 사용자 중 <span style={{ fontWeight: 'bold' }}>오늘</span> 쿠폰을 지급받은 사용자"들의 'membership_alarm' 칼럼을 1로 그렇지 않으면 0으로 업데이트 합니다.</Guide>
                    <Button style={{ marginTop: '8px' }} variant='contained' size="large" color="primary" onClick={handleUpdateKakaoGroupFile}>카카오그룹파일 업데이트</Button>
                    
                </div>
            </Paper>

            <Paper className="pagePadding" style={{ marginTop: '24px' }}>
                <PageTitle>접속통계</PageTitle>

                <VisitCount yearmonth={yearmonth} />
            </Paper>

            <div style={{ marginTop: '24px' }}>
                <GoogleOAuthRequest />
            </div>
        </div>
	);
}

export default DashboardPage;