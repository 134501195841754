import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { AppContextProvider } from './contexts/app.context';
import { AuthContextProvider } from './contexts/auth.context';
import GlobalStyles from "./components/GlobalStyles";
import { MemoryContextProvider } from "./contexts/memory.context";


function App() {
    return (
        <>
            <GlobalStyles />
            <Router basename={process.env.REACT_APP_BASE_URL}>
                <AppContextProvider>
                    <AuthContextProvider>
                        <MemoryContextProvider>
                            <Routes />
                        </MemoryContextProvider>
                    </AuthContextProvider>
                </AppContextProvider>
            </Router>
        </>
    );
}


export default App;
