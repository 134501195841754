import React, { useState, useContext } from 'react';
import { useTheme, Button, TextField, Paper, Dialog, DialogContent, DialogContentText, DialogActions, MenuItem, DialogTitle } from '@material-ui/core';
import Loading from 'components/Loading';
import useBucketSearch from '../useBucketSearch';
import PageTitle from 'components/PageTitle';
import ReadyBucketTable from './ReadyBucketTable';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import deliveryServices from 'constants/delivery-services.constant';
import OrderDetail from 'views/order/detail/OrderDetail';


export default function ReadyBucketPage() {
	const theme = useTheme();
    const { showAlert, showSnackbar } = useContext(AppContext);
    
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useBucketSearch('ready', page, trigger);

    // ------ send bucket ------
    const [ invoiceNumberDialogOpen, setInvoiceNumberDialogOpen ] = useState(false);
    const [ targetBucket, setTargetBucket ] = useState();
    const [ deliveryService, setDeliveryService ] = useState();
    const [ invoiceNumber, setInvoiceNumber ] = useState('');
    const handleSendBucketClick = (bucket) => {
        setTargetBucket(bucket);
        setInvoiceNumberDialogOpen(true);
    }

    const handleSendBucket = () => {
        if (!deliveryService) return showAlert('택배사를 선택하세요.');
        if (!invoiceNumber) return showAlert('송장번호를 입력하세요.');

        const data = {
            bucketId: targetBucket.id,
            deliveryService,
            invoiceNumber,
        }
        API.put('/bucket/send-bucket', data)
        .then(() => {
            showSnackbar('발송처리했습니다.');
            setTrigger(new Date().getTime());
        })
        .finally(() => {
            setTargetBucket(null);
            setInvoiceNumber('');
        });
    }
    // ------ end: send bucket ------

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    // order info
    const [ orderModalOpen, setOrderModalOpen ] = useState(false);
    const [ orderId, setOrderId ] = useState();
    const handleOrderInfo = (orderId) => () => {
        setOrderId(orderId);
        setOrderModalOpen(true);
    }
    // end: order info

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>발송준비</PageTitle>

                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <ReadyBucketTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage}
                            handleOrderInfo={handleOrderInfo}
                            handleSendBucketClick={handleSendBucketClick}
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>

            <Dialog
                open={invoiceNumberDialogOpen}
                onClose={() => { setInvoiceNumberDialogOpen(false); }}
            >
                <DialogContent style={{ minWidth: '320px' }}>
                    <DialogContentText>택배사와 송장번호를 입력하세요.</DialogContentText>
                    
                    <TextField
                        select
                        style={{ width: '100%' }}
                        size='small'
                        variant="outlined"
                        label={'택배사'}
                        value={deliveryService}
                        onChange={(event) => setDeliveryService(event.target.value) }
                    >
                        {deliveryServices.map(el => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}
                    </TextField>

                    <TextField
                        label="송장번호" 
                        variant="outlined"
                        size='small'
                        style={{width: '100%', marginTop: '8px'}}
                        value={invoiceNumber}
                        onChange={(event) => setInvoiceNumber(event.target.value) }
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setTargetBucket(null);
                            setInvoiceNumber('');
                            setInvoiceNumberDialogOpen(false);
                        }} 
                        color="secondary"
                    >취소</Button>
                    
                    <Button 
                        onClick={() => {
                            handleSendBucket();
                            setInvoiceNumberDialogOpen(false); 
                        }} 
                        color="primary" 
                        autoFocus
                    >확인</Button>
                </DialogActions>
            </Dialog>

            {/* order info */}
            <Dialog fullScreen open={orderModalOpen} onClose={() => { setOrderModalOpen(false); }}>
                <DialogTitle>주문정보</DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: '8px', display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button variant='contained' color="primary" onClick={() => { setOrderModalOpen(false); }}>닫기</Button>
                    </div>
                    {orderId && <OrderDetail orderId={orderId} />}
                </DialogContent>
            </Dialog>
        </div>
	);
}
