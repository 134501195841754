import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, TextField, Typography, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../../api';
import colors from '../../../appcolors';
import { AuthContext } from '../../../contexts/auth.context';


const SigninPage = (props) => {
	
    const { fetchMyinfo, loginRedirectURL } = useContext(AuthContext);

	const passwordRef = useRef();

	const handleEmailInputKeydown = (event) => {
		// console.log(event);
		if(event.key === 'Tab') {
			event.preventDefault();
			passwordRef.current.focus();
		}
	}

	const history = useHistory();
    
	return(
		<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={Yup.object({
					email: Yup.string().required('필수입력입니다.'),
					password: Yup.string().required('필수입력입니다.'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					const params = values;
					API.get('/auth/signin', { params })
					.then(() => {
						return fetchMyinfo();
					})
					.then(() => { history.push(loginRedirectURL); })
                    .finally(setSubmitting(false));
				}}
			>
				{({ values, errors, dirty, touched, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit} style={{ width: '100%', padding: '40px 16px', display: 'flex', justifyContent: 'center' }}>
						<Box width={{ xs: '100%', sm: '500px', md: '600px' }} display="flex" flexDirection="column" >
							<Typography variant="h6" style={{ color: colors.gray600, marginBottom: '5px' }}>LOGIN</Typography>
							
							<TextField
                                style={{ marginTop: '24px' }}
								label={'이메일'}
								variant="outlined"
								type="email"
								autoFocus
								required={true}
								onKeyDown={handleEmailInputKeydown}
								onChange={handleChange('email')}
								onBlur={handleBlur('email')}
								helperText={(touched.email && errors.email) || '이메일을 입력하세요.'}
								error={!!(touched.email && errors.email)}
								value={values.email}
							/>
							
							<TextField
								inputRef={passwordRef}
                                style={{ marginTop: '24px' }}
								label={'비밀번호'}
								variant="outlined"
								type="password"
								required={true}
								onChange={handleChange('password')}
								onBlur={handleBlur('password')}
								helperText={(touched.password && errors.password) || '비밀번호를 입력하세요.'}
								error={!!(touched.password && errors.password)}
								value={values.password}
							/>

                            <div style={{ marginTop: '24px' }}><Button variant="contained" type="submit" color="primary" disabled={!dirty || !isValid || isSubmitting}>로그인</Button></div>
						</Box>
					</form>
				)}
			</Formik>
		</div>
	)
}


export default SigninPage;
