module.exports = {
    primaryDark: '#357a38',
    primary: '#4caf50',
    primaryLight: '#6fbf73',
    primaryTint: '#e8f5e9',
    secondaryDark: '#007bb2',
    secondary: '#00b0ff',
    secondaryLight: '#33bfff',
    error: '#f44336',
    warn: '#ff9800',
    info: '#2196f3',
    success: '#4caf50',
    textPrimary: 'rgba(0,0,0,0.87)',
    textSecondary: 'rgba(0,0,0,0.54)',
    textDisabled: 'rgba(0,0,0,0.38)',
    textHint: 'rgba(0,0,0,0.38)',
    textNeutral: 'rgb(45,55,72)',
    divider: 'rgba(0,0,0,0.12)',
    gray50: '#FAFAFA',
    gray100: '#F5F5F5',
    gray200: '#EEEEEE',
    gray300: '#E0E0E0',
    gray400: '#BDBDBD',
    gray500: '#9E9E9E',
    gray600: '#757575',
    gray700: '#616161',
    gray800: '#424242',
    gray900: '#212121',

    background: '#fff',
    pageGray: '#f5f5f5',
    borderColor: '#eeeeee',
};