import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import { Container, Box, Grid, Typography, makeStyles, useTheme, Button, Link, TextField, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import colors from 'appcolors';
import useApi from 'hooks/useApi';
import API from 'api';
import PageTitle from 'components/PageTitle';
import ColumnTableView from './ColumnTableView';



const useStyles = makeStyles((theme) => ({

}));

export default function ColumnListPage() {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();

    const [ columns, setColumns ] = useState();

    useApi(() => {
        API.get('/column/all-columns')
        .then(setColumns);
    }, []);

    const [ columnCodes, setColumnCodes ] = useState();
    useApi(() => {
        API.get('/column/column-codes')
        .then(setColumnCodes);
    }, []);

    if (!columns) return <Loading />

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>칼럼</PageTitle>

                {columns && columnCodes && <ColumnTableView
                    rows={columns} 
                    columnCodes={columnCodes}
                />}
            </Paper>
        </div>
	);
}
