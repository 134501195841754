import React, { createContext, useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/Error';

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
    // snackbar 
	const [ snackbarOpen, setSnackbarOpend ] = useState(false);
    const [ snackbarMessage, setSnackbarMessage ] = useState();
    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpend(true);
    };
    const cloaseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpend(false);
    };
    // end: snackbar

    // alert
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ alertContent, setAlertContent ] = useState();

    const showAlert = (message) => {
        setAlertContent(message);
        setAlertOpen(true);
    };
    // end: alert

    // dialog
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogData, setDialogData ] = useState();
    const showDialog = (data) => {
        setDialogData(data);
        setDialogOpen(true);
    }
    // end: dialog

	return (
		<AppContext.Provider
			value={{
				showSnackbar,

                showAlert,

                showDialog,
			}}
		>
			{children}

            {/* Snackbar */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={cloaseSnackbar}
                message={snackbarMessage}
            />

            {/* Alert */}
            <Dialog
                open={alertOpen}
                onClose={() => { setAlertOpen(false); }}
            >
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: '240px' }}>
                        <ErrorIcon fontSize="large" style={{ marginRight: '16px' }} color="error" />
                        <DialogContentText id="alert-dialog-description">{alertContent}</DialogContentText>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAlertOpen(false); }} color="primary" autoFocus>확인</Button>
                </DialogActions>
            </Dialog>

            {/* dialog */}
            {dialogData && <Dialog
                open={dialogOpen}
                onClose={() => { setDialogOpen(false); }}
            >
                {dialogData.title && <DialogTitle>{dialogData.title}</DialogTitle>}
                <DialogContent style={{ minWidth: '240px' }}>
                    {dialogData.message && <DialogContentText>{dialogData.message}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDialogOpen(false);
                        if (dialogData.second?.onClick) dialogData.second.onClick();
                    }} color="secondary">{dialogData.second?.text || '취소'}</Button>

                    <Button onClick={() => {
                        setDialogOpen(false);
                        dialogData.first.onClick();
                    }} color="primary" autoFocus>{dialogData.first.text || '확인'}</Button>
                </DialogActions>
            </Dialog>}

		</AppContext.Provider>
	);
};

export {
	AppContext,
	AppContextProvider
};