import { Typography } from '@material-ui/core';
import React from 'react';
import colors from '../appcolors';


export default function Guide({ children }) {
    return (
        <div style={{ borderLeft: '6px solid #4caf50', padding: '1rem 1rem 1rem 2rem', color: colors.primaryDark, backgroundColor: colors.primaryTint, }}>
            <Typography variant="body1">{children}</Typography>
        </div>
    );
}

