import React, { useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { user_pipe } from '../../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';
import API from 'api';
import moment from 'moment';
import { AppContext } from 'contexts/app.context';


const NotifyEmailTable = ({ rows, generateDate }) => {
    const { showSnackbar } = useContext(AppContext);

    const handleSendEmail = (user) => () => {
        const data = {
            userId: user.id,
            generateDate: generateDate,
        };
        API.post('/membership/notify-15day', data)
        .then(() => {
            showSnackbar('이메일을 보냈습니다.');
        })
    }


	return (
        <TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow className='content-head'>
						<StyledTableCell align="left">{'사용자'}</StyledTableCell>
                        <StyledTableCell align="left">{'이메일'}</StyledTableCell>
						<StyledTableCell align="left">{'이메일 알림 동의'}</StyledTableCell>
						<StyledTableCell align="left">{'카카오 알림 동의'}</StyledTableCell>
						<StyledTableCell align="left">{'사용여부'}</StyledTableCell>
						<StyledTableCell align="left" className='content-exception'>관리</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id} className='content-body'>
							<StyledTableCell align="left">{user_pipe(row)}</StyledTableCell>
                            <StyledTableCell align="left">{row.email}</StyledTableCell>
							<StyledTableCell align="left">{row.emailAgree ? 'O' : 'X'}</StyledTableCell>
							<StyledTableCell align="left">{row.kakaoChannelAgree ? 'O' : 'X'}</StyledTableCell>
							<StyledTableCell align="left">{row.usedAt ? 'O' : 'X'}</StyledTableCell>
							<StyledTableCell align="left" className='content-exception'>
								<Box display={'flex'}>
									{row.emailAgree && !row.usedAt && <Button variant='contained' color="secondary" size="small" onClick={handleSendEmail(row)}>이메일 보내기</Button>}
								</Box>
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default NotifyEmailTable;