import React from 'react';
import { TableFooter, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../components/TablePaginationAction';
import { datetimePipe } from '../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';
import { coupon_code_pipe } from 'pipes';
import moment from 'moment';


const CouponTable = ({ rows, totalCount, rowsInPage, page, setPage }) => {
	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

	return (
        <TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
						<StyledTableCell align="left">{'구분'}</StyledTableCell>
						<StyledTableCell align="left">{'발급자'}</StyledTableCell>
						<StyledTableCell align="left">{'소유자'}</StyledTableCell>
						<StyledTableCell align="left">{'발급일시'}</StyledTableCell>
                        <StyledTableCell align="left">{'사용가능 시작일자'}</StyledTableCell>
                        <StyledTableCell align="left">{'사용가능 종료일자'}</StyledTableCell>
                        <StyledTableCell align="left">{'할인율'}</StyledTableCell>
                        <StyledTableCell align="left">{'발급자 메모'}</StyledTableCell>
                        <StyledTableCell align="left">{'전달여부'}</StyledTableCell>
                        <StyledTableCell align="left">{'사용여부'}</StyledTableCell>
                        <StyledTableCell align="left">{'사용일시'}</StyledTableCell>
                        <StyledTableCell align="left">{'주문번호'}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id}>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                            <StyledTableCell align="left">{coupon_code_pipe(row.code)}</StyledTableCell>
                            <StyledTableCell align="left">{row.generator ? `${row.generator.nickname} [${row.generatorId}]` : '-'}</StyledTableCell>
                            <StyledTableCell align="left">{row.user ? `${row.user.nickname} [${row.userId}]` : '-'}</StyledTableCell>
							<StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>
							<StyledTableCell align="left">{row.startDate ? moment(row.startDate).format('YYYY-MM-DD') : '-'}</StyledTableCell>
                            <StyledTableCell align="left">{moment(row.endDate).format('YYYY-MM-DD')}</StyledTableCell>
                            <StyledTableCell align="left">{row.discountRate}</StyledTableCell>
                            <StyledTableCell align="left">{row.generatorMemo || '-'}</StyledTableCell>
                            <StyledTableCell align="left">{row.markGived ? '전달함' : '미전달'}</StyledTableCell>
                            <StyledTableCell align="left">{row.usedAt ? '사용함' : '미사용'}</StyledTableCell>
                            <StyledTableCell align="left">{datetimePipe(row.usedAt)}</StyledTableCell>
                            <StyledTableCell align="left">{row.orderId || '-'}</StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={12} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={7}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

export default CouponTable;