import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Typography, makeStyles, Button, TextField, Paper, ImageList, ImageListItem, ImageListItemBar, Switch, FormControlLabel } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import colors from '../../../appcolors';
import CircularButton from 'components/CircularButton';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import Loading from 'components/Loading';



const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        marginBottom: '8px',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
}));

const OptionImage = ({ reload, option, item }) => {
    const classes = useStyles();

    const { showSnackbar } = useContext(AppContext);

    const [ fileInfos, setFileInfos ] = useState();
    useEffect(() => {
        if (option) {
            setFileInfos(option.fileInfos);
        }
    }, [ option ]);

    const isMainColor = useMemo(() => {
        return item.mainColorOptionId === option.id;
    }, [ item ]);

    // 색상설정
    const handleChangeColor = event => {
        const colorcode = event.target.value;

        const body = {
            ...option,
            value: colorcode,
        };

        API.put('/option/option', body)
        .then(() => {
            showSnackbar('색상을 설정했습니다.');
            reload();
        })
    }

    // 주요색상
    const handleSwitchMainColor = event => {
        const makeMain = event.target.checked;

        const body = {
            itemId: item.id,
            optionId: makeMain ? option.id : null,
        }
        API.put('/item/make-color-option-main', body)
        .then(() => {
            showSnackbar('상품의 주요색상을 설정했습니다.');
            reload();
        })
    }

    // 사진
    const [ imageURL, setImageURL ] = useState('');
    const handleImageUrlChange = (event) => {
        setImageURL(event.target.value);
    }
    const handleAddImageURL = () => {
        if (!imageURL) return showSnackbar('URL 을 입력하세요.');
        const fileInfo = {
            category: 'option',
            refId: option.id,
            downloadURL: imageURL,
            type: 'image',
        };
        API.post('/common/append-fileinfo', fileInfo)
        .then(fileInfo => {
            showSnackbar('추가했습니다.');
            setImageURL('');
            reload();
        });
    }

    const [ files, setFiles ] = useState([]);
    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFiles(result => {
                return [
                    ...result,
                    ...e.target.files,
                ]
            });
        }
    }

    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const handleAddFileInfo = () => {
        if (files.length === 0) return;

        const promisis = files.map(file => {
            const data = new FormData();
            data.append('file', file);
            data.append('category', 'option');
            data.append('refId', option.id);
            data.append('withThumbnail', true);

            return API.post('/common/append-file', data, {
                timeout: 0,
            });
        })

        setFileUploadLoading(true);
        Promise.all(promisis)
        .then(() => {
            reload();
            setFiles([]);
        })
        .finally(() => { setFileUploadLoading(false); });
    }

    const handleModifyFileinfos = () => {
        const body = fileInfos.map(info => {
            return ({
                id: info.id,
                order: info.order,
            });
        });
        API.put('/common/fileinfos', body)
        .then(() => {
            reload();
            showSnackbar('순서를 수정했습니다.');
        })
    }

    const handleRemoveFile = (fileInfo) => {
        API.delete('/common/remove-file/' + fileInfo.id)
        .then(() => {
            reload();
            showSnackbar('파일을 삭제했습니다.');
        });
    }
    // end: 사진

    const handleMakeMain = (fileInfo) => {
        API.put('/common/make-file-main', { id: fileInfo.id })
        .then(() => {
            reload();
            showSnackbar('대표사진으로 지정했습니다.');
        });
    }

    if (!fileInfos) return null;

    return (<div key={option.id}>
        <Typography variant="h6">{option.name}</Typography>

        <div className={classes.section}>
            <Typography variant="subtitle2" className={classes.label}>사진</Typography>
            <ImageList rowHeight={160} cols={6} style={{ alignSelf: 'stretch' }}>
                {fileInfos.map((fileInfo, idx) => <ImageListItem key={fileInfo.id} cols={1}>
                    <img src={fileInfo.downloadURL}></img>
                    <ImageListItemBar
                        actionIcon={<>
                            {/* <Button type="button" size="small" color={fileInfo.main ? 'secondary' : 'default' } variant="contained" onClick={() => { handleMakeMain(fileInfo) }}>대표사진</Button> */}
                            <input type="number" placholder={'노출순서'} style={{ width: '40px' }} value={fileInfo.order || ''} onChange={(event) => {
                                setFileInfos(prev => {
                                    prev[idx].order = event.target.value;
                                    return [
                                        ...prev,
                                    ];
                                })
                            }}></input>
                            <Button type="button" size="small" style={{ backgroundColor: 'red', color: 'white', marginLeft: '8px', marginRight: '8px' }} variant="contained" onClick={() => { handleRemoveFile(fileInfo) }}>삭제</Button>
                        </>}
                    />
                </ImageListItem>)}
            </ImageList>
            <div style={{ marginTop: '8px', display: 'flex', alignItems: 'flex-end' }}>
                <Button variant={'outlined'} color="primary" onClick={handleModifyFileinfos}>순서 변경</Button>
                <Typography variant='body2' style={{ marginLeft: '8px', color: colors.textSecondary }}> {'순서를 변경하려면 클릭해주세요. 낮을수록 먼저 출력합니다.'}</Typography>
            </div>
        </div>
        
        {/* file upload */}
        <div className={classes.section}>
            <div style={{ marginTop: '16px', border: '1px solid #dddddd', padding: '4px' }}>
                <input type="file" accept="image/*" onChange={handleFileChange} multiple />
                {files.length > 0 && <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '6px' }}>
                    <CircularButton type="button" size="small" variant="outlined" loading={fileUploadLoading} onClick={handleAddFileInfo}>업로드</CircularButton>
                    <Typography style={{ marginLeft: '4px', color: colors.textSecondary }} variant="caption">최대크기: 5MByte</Typography>
                </div>}
            </div>

            <div style={{ marginTop: '8px', display: 'flex', width: '100%' }}>
                <TextField
                    variant="outlined"
                    className={classes.input}
                    style={{ flex: 1, marginRight: '8px' }}
                    size="small"
                    onChange={handleImageUrlChange}
                />
                <Button variant="outlined" size="small" color="secondary" onClick={handleAddImageURL}>이미지 URL 추가</Button>
            </div>
        </div>

        <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" style={{ marginBottom: 0, marginRight: '16px' }}>색상설정</Typography>
            <input type="color" name="colorcode" value={option.value || ''} onChange={handleChangeColor}></input>
            {!option.value && <small style={{ color: 'red', marginLeft: '16px' }}>(현재 색상이 설정되어 있지 않습니다.)</small>}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" style={{ marginBottom: 0, marginRight: '16px' }}>주요색상 여부</Typography>
            <FormControlLabel
                control={<Switch checked={isMainColor} onChange={handleSwitchMainColor} />}
            />
        </div>
    </div>);
}




export default function OptionImagesPage() {
    const { itemCode } = useParams();
    const history = useHistory();

    const [ options, setOptions ] = useState();
    const [ item, setItem ] = useState();

    const [ trigger, setTrigger ] = useState();

    const [ message, setMessage ] = useState();

    useEffect(() => {
        loadData();
    }, [ trigger ]);

    const loadData = () => {
        API.get('/item/item/' + itemCode)
        .then(item => {
            const colorSelection = item.selects.find(el => el.name === '색상');
            if (!colorSelection) {
                setMessage('색상 옵션이 없습니다.');
            }
            else {
                setOptions(colorSelection.options);
            }
            setItem(item);
        });
    }

    const reload = () => {
        setTrigger(new Date().getTime());
    }

    if (message) return message;

    if (!options || !item) return (<Loading />);
    
	return (
        <div className="pageMargin">
            <h3>옵션 이미지</h3>

            <Paper className="pagePadding">
                {options.map(option => <div key={option.id} style={{ marginBottom: '64px' }}><OptionImage option={option} reload={reload} item={item} /></div>)}
            </Paper>

            <div style={{ marginTop: '16px' }}>
                <Button variant="contained" color="primary" onClick={() => history.goBack()}>뒤로</Button>
            </div>
        </div>
	);
}