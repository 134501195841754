import { useEffect, useState } from "react";
import axios from "axios";
import useApi from "hooks/useApi";
import API from "api";



export default function useGetInquiries(keyword, state, page, trigger) {
    const [ rows, setRows ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ rowsInPage, setRowsInPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);

    useEffect(() => {
        setRows([]);
    }, [ keyword, state, trigger, page ]);

    useApi(() => {
        let cancel;
        setLoading(true);
        const params = { 
            keyword, 
            page,
            state,
        };
        API.get('/admin/inquiries', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setTotalCount(data.totalCount);
            setRowsInPage(data.rowsInPage);
            setRows(rows => rows.concat(data.rows));
            setMayMore(data.rows.length > 0);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ keyword, state, page, trigger ]);

    return { loading, rows, mayMore, totalCount, rowsInPage };
}
