import React, { createContext, useState } from 'react';
import API from 'api';

const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
	// const [ token, setToken ] = useState(null);
	const [ me, setMe ] = useState(null);
	
	// token 방식에서, 쿠키 방식으로 변경해서 사용안함
	// const setAuthInfo = async ({ token, user }) => {
	// 	try {
	// 		localStorage.setItem('token', token);
	// 		setToken(token);
	// 		setMe(user);
	// 	}
	// 	catch(error) {
	// 		console.error(error);
	// 		clearAuthInfo();
	// 	}
	// }

	const clearAuthInfo = async () => {
		try {
			setMe(null);
		}
		catch(error) {
			console.error(error);
		}
	};

	// call after myinfo changed
	const fetchMyinfo = async () => {
		try {
			const user = await API.get('/auth/myinfo');
			setMe(user);
		}
		catch(error) {
			console.error(error);
			clearAuthInfo();
		}
	}

	// login page control
	const [ loginRedirectURL, setLoginRedirectURL ] = useState('/');

	return (
		<AuthContext.Provider
			value={{    
				me,
				clearAuthInfo,
				fetchMyinfo,

				loginRedirectURL,
				setLoginRedirectURL,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export {
	AuthContext,
	AuthContextProvider
};