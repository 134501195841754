import React, { useState, useContext } from 'react';
import { Box, Typography, Button, TextField, Paper, Chip, Divider } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import colors from 'appcolors';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import SmsList from './SmsList';


const SmsPage = () => {
    const { showSnackbar, showAlert, showDialog } = useContext(AppContext);

    const [ mobiles, setMobiles ] = useState([]);
    const [ message, setMessage ] = useState('[MNX15] ');
    const [ logMessage, setLogMessage ] = useState('');

    // 보내기
    const [ trigger, setTrigger ] = useState();
    const handleSendClick = () => {
        if (mobiles.length === 0) return showAlert('수신자를 한명이상 입력해주세요.');
        if (!message) return showAlert('메시지를 입력해주세요.');

        const dialogData = {
            title: 'SMS 보내기',
            message: `${mobiles.length}명의 수신자에게 문자를 보내시겠습니까?`,
            first: {
                onClick: () => {
                    const data = {
                        mobiles,
                        message,
                        logMessage,
                    };
                    API.post('/admin/send-sms', data)
                    .then(() => {
                        showSnackbar('메세지를 보냈습니다.');
                        setTrigger(new Date().getTime());
                    });
                }
            },
            second: true,
        }
        showDialog(dialogData);
    }

    // interface
    const [ mobile, setMobile ] = useState('');
    const handleDirectClick = () => {
        if (!mobile) return showSnackbar('번호를 입력해주세요.');
        else if (mobile.length !== 11) return showSnackbar('번호를 숫자만 11자 입력해주세요.');
        if (mobiles.indexOf(mobile) > -1) return showSnackbar('이미 입력된 전화번호가 있습니다.');

        setMobiles(result => {
            return ([
                ...result,
                mobile,
            ]);
        });
        setMobile('');
    }
    const handleDelete = (mobile) => () => {
        setMobiles(mobiles.filter(item => item !== mobile));
    }

    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const handleCafeuserAppend = () => {
        if (!(startDate && endDate)) return showAlert('시작일 종료일을 입력해주세요.');

        const params = {
            from: startDate,
            to: endDate,
        };
        API.get('/user/cafeuser-mobiles', { params })
        .then(mobiles => {
            setMobiles(result => {
                return ([
                    ...result,
                    ...mobiles,
                ]);
            });
            showSnackbar(`${mobiles.length}개의 핸드폰번호를 추가했습니다.`);
        })
    }
    // end: interface

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>SMS 보내기</PageTitle>
                
                {/* mobile numbers */}
                <Box>
                    <Typography variant="body1">수신자 목록 {mobiles.length > 0 && <span style={{ color: colors.textSecondary}}>{mobiles.length}명</span>}</Typography>
                    <Box style={{ marginTop: '8px', minHeight: '56px', border: '1px solid #bdbdbd', borderRadius: '4px' }}>
                        {mobiles.map(mobile => <Chip
                            key={mobile}
                            style={{ margin: '2px' }}
                            label={mobile}
                            onDelete={handleDelete(mobile)}
                        />)}
                    </Box>
                    <Button style={{ marginTop: '8px' }} variant='contained' size="small" color="primary" onClick={() => { setMobiles([]); }}>초기화</Button>
                </Box>

                <Divider style={{ margin: '24px 0'}} />

                {/* mobile number interface */}
                <Box>
                    <Typography variant="body1">번호 입력 인터페이스</Typography>

                    {/* direct input */}
                    <Box style={{ marginTop: '16px' }}>
                        <Typography variant="subtitle2">직접 입력</Typography>
                        <Box style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
                            <TextField
                                variant="outlined"
                                size='small'
                                style={{ marginRight: '8px' }}
                                placeholder={'핸드폰번호 숫자만 입력'}
                                onKeyDown={ev => { if(ev.key === 'Enter') handleDirectClick(); }}
                                onChange={(event) => setMobile(event.target.value)}
                                value={mobile}
                            />
                            <Button variant='contained' color='primary' size='small' onClick={handleDirectClick}>추가</Button>
                        </Box>
                    </Box>

                    {/* 스마트하게 사용자번호 입력 */}
                    <Box style={{ marginTop: '24px' }}>
                        <Typography variant="subtitle2">카페24 홈페이지 사용자 핸드폰번호 입력</Typography>
                        
                        <Box style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
                            <TextField
                                label="회원가입 시작일"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => { setStartDate(event.target.value) }}
                                value={startDate}
                            />
                            <TextField
                                label="회원가입 종료일"
                                type="date"
                                style={{ marginLeft: '16px' }}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => { setEndDate(event.target.value) }}
                                value={endDate}
                            />
                            <Button style={{ marginLeft: '16px' }} variant='contained' color='primary' size='small' onClick={handleCafeuserAppend}>추가</Button>
                        </Box>
                    </Box>
                </Box>

                <Divider style={{ margin: '24px 0'}} />

                {/* message */}
                <Box style={{ marginTop: '24px' }}>
                    <Typography variant="body1">메세지 (수신자들에게 보여지는 메세지 입력) *</Typography>
                    <TextField
                        multiline
                        style={{ minWidth: '400px' }}
                        variant="outlined"
                        placeholder="수신자들에게 보여지는 메세지 입력"
                        required={true}
                        rows={3}
                        onChange={(event) => setMessage(event.target.value)}
                        value={message}
                    />
                </Box>

                {/* log message */}
                <Box style={{ marginTop: '24px' }}>
                    <Typography variant="body1">로그 (관리자 확인용 로그 메세지)</Typography>
                    <TextField
                        size="small"
                        style={{ minWidth: '400px' }}
                        variant="outlined"
                        placeholder="예) 2022년 7월 광고성 문자"
                        onChange={(event) => setLogMessage(event.target.value)}
                        value={logMessage}
                    />
                </Box>

                <Divider style={{ margin: '24px 0'}} />

                <Button variant='contained' size='large' color="primary" onClick={handleSendClick}>보내기</Button>
            </Paper>

            <div style={{ marginTop: '24px' }}>
                <SmsList trigger={trigger} />
            </div>
        </div>
	);
}

export default SmsPage;