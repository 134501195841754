import React from 'react';
import { TableFooter, TablePagination, Button, Link } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { datetimePipe, destinationAddressPipe, stuffOptionPipe, user_pipe } from '../../../pipes';
import StyledTableCell from 'components/StyledTableCell';
import StyledTableRow from 'components/StyledTableRow';


export default function ReadyBucketTable({ 
	rows, 
	totalCount, 
	rowsInPage, 
	page, 
	setPage, 
	handleOrderInfo,
	handleSendBucketClick,
}) {
	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

	return (
        <TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
						<StyledTableCell align="left">{'주문번호'}</StyledTableCell>
						<StyledTableCell align="left">{'사용자'}</StyledTableCell>
						<StyledTableCell align="left">{'제품'}</StyledTableCell>
						<StyledTableCell align="left">{'옵션'}</StyledTableCell>
						<StyledTableCell align="left">{'수취인'}</StyledTableCell>
						<StyledTableCell align="left">{'연락처'}</StyledTableCell>
						<StyledTableCell align="left">{'이메일'}</StyledTableCell>
						<StyledTableCell align="left">{'배송지'}</StyledTableCell>
						<StyledTableCell align="left">{'배송메모'}</StyledTableCell>
						<StyledTableCell align="left">{'발송준비일시'}</StyledTableCell>
						<StyledTableCell align="left">{'관리'}</StyledTableCell>

					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id}>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
							<StyledTableCell><Link href="#" onClick={handleOrderInfo(row.orderId)}>{row.orderId}</Link></StyledTableCell>
							<StyledTableCell align="left">{user_pipe(row.user)}</StyledTableCell>
							<StyledTableCell align="left">{row.stuff.item.code}</StyledTableCell>
							<StyledTableCell align="left">{stuffOptionPipe(row.stuff)}</StyledTableCell>
							<StyledTableCell align="left">{row.destination.name}</StyledTableCell>
							<StyledTableCell align="left">{row.destination.mobile}</StyledTableCell>
							<StyledTableCell align="left">{row.destination.email}</StyledTableCell>
							<StyledTableCell align="left">{destinationAddressPipe(row.destination)}</StyledTableCell>
							<StyledTableCell align="left">{row.destination.memo}</StyledTableCell>
							<StyledTableCell align="left">{datetimePipe(row.readyAt)}</StyledTableCell>
							<StyledTableCell align="left"><Button size="small" variant="contained" color="secondary" onClick={() => { handleSendBucketClick(row); }}>발송처리</Button></StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={12} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={12}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
