import React, { useState, useEffect } from 'react';
import { Typography, Paper } from '@material-ui/core';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import colors from 'appcolors';
import API from 'api';
import NotifyEmailTable from './NotifyEmailTable';
import moment from 'moment';


const NotifyEmailPage = () => {
    const [ rows, setRows ] = useState();
    const [ date, setDate ] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        const params = {
            t_date: date,
        };
        API.get('/membership/get-coupon-membership-users', { params })
        .then((users) => {
            setRows(users);
        })
    }, [ date ]);

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>Day15 쿠폰 이메일 알림</PageTitle>

                <Typography style={{ marginBottom: '16px', color: colors.textSecondary }}>* 지정일에 쿠폰을 받은 멤버십 사용자 현황입니다.</Typography>

                <input 
                    type="date" 
                    style={{ marginBottom: '16px' }}
                    value={date}
                    onChange={e => {
                        setDate(e.target.value);
                    }}
                />

                {rows ? <>
                    <NotifyEmailTable
                        rows={rows}
                        generateDate={date}
                    />
                </> : <Loading />}
            </Paper>
        </div>
	);
}

export default NotifyEmailPage;