import React, { useContext, useRef, useState } from 'react';
import { useTheme, Button, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import OrderTable from './OrderTable';
import useOrderSearch from './useOrderSearch';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import OrderDetail from '../detail/OrderDetail';


const OrderListPage = () => {
	const theme = useTheme();

    const { showSnackbar } = useContext(AppContext);
    
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();
    const [ userId, setUserId ] = useState('');
    const [ nickname, setNickname ] = useState('');
    const [ params, setParams ] = useState({ state: 'done', method: 'card' });

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useOrderSearch(params, page, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    // refund
    const [ refundModalOpen, setRefundModalOpen ] = useState(false);
    const [ reason, setReason ] = useState('');
    const [ refundAmount, setRefundAmount ] = useState('');
    const refundOrderRef = useRef();
    const handleRefundClick = (order) => {
        refundOrderRef.current = order;
        const value = order.price + order.deliveryPrice - order.refundAmount;
        setReason('');
        setRefundAmount(value + '');
        setRefundModalOpen(true);
    }
    const handleRefund = () => {
        const data = {
            orderId: refundOrderRef.current.id,
            amount: refundAmount,
            reason,
        };
        API.post('/order/refund-order', data)
        .then(() => {
            showSnackbar('환불했습니다.');
            handleRefresh();
        })
        .finally(() => { setRefundModalOpen(false); })
    }
    // end: refund

    // search
    const handleSearchClick = () => {
        setParams({
            state: 'done',
            method: 'card',
            userId,
            nickname,
        });
        setPage(0);
    }
    // end: search

    // show buckets
    const [ bucketsModalOpen, setBucketsModalOpen ] = useState(false);
    const [ selected, setSelected ] = useState();
    const handleShowBucketsClick = (order) => {
        setSelected(order);
        setBucketsModalOpen(true);
    }
    // end: show buckets

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>주문내역 - 전자결제</PageTitle>
                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                label="닉네임"
                                size='small'
                                variant="outlined"
                                onChange={(event) => { setNickname(event.target.value) }}
                                value={nickname}
                            />
                            <Button style={{ marginLeft: '8px' }} variant='contained' color="primary" onClick={handleSearchClick}>검색</Button>
                        </div>
                        
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <OrderTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage}
                            handleRefundClick={handleRefundClick}
                            handleShowBucketsClick={handleShowBucketsClick}
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>

            {/* refund modal */}
            <Dialog open={refundModalOpen} onClose={() => { setRefundModalOpen(false); }}>
                <DialogTitle>환불하기</DialogTitle>
                <DialogContent>
                    <DialogContentText>환불금액을 입력하세요, 입력한 금액만큼 카드 취소됩니다.</DialogContentText>
                    <TextField
                        autoFocus
                        type="number"
                        margin="dense"
                        label="환불금액"
                        fullWidth
                        value={refundAmount}
                        onChange={event => { setRefundAmount(event.target.value); }}
                    />
                    <TextField
                        margin="dense"
                        label="환불사유, 예) 앱관리자 권한"
                        fullWidth
                        value={reason}
                        onChange={event => { setReason(event.target.value); }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRefundModalOpen(false)} color="primary">취소</Button>
                    <Button onClick={handleRefund} color="primary">확인</Button>
                </DialogActions>
            </Dialog>

            {/* buckets modal */}
            <Dialog fullScreen open={bucketsModalOpen} onClose={() => { setBucketsModalOpen(false); }}>
                <DialogTitle>주문정보</DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: '8px', display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button variant='contained' color="primary" onClick={() => { setBucketsModalOpen(false); }}>닫기</Button>
                    </div>
                    {selected && <OrderDetail orderId={selected.id} />}
                </DialogContent>
            </Dialog>
        </div>
	);
}

export default OrderListPage;