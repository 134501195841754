import React, { useState, useContext, useMemo, Fragment } from 'react';
import { Typography, Button, Link, Card, TextField, CardContent } from '@material-ui/core';
import API from '../../api';
import colors from '../../appcolors';
import globals from '../../appglobals';
import Divider from '../../components/Divider';
import { AppContext } from '../../contexts/app.context';
import { AuthContext } from '../../contexts/auth.context';
import { datetimePipe, fileNameFromPathPipe } from '../../pipes';


const Ticket = ({ ticket, loadData, categories }) => {
    const { me } = useContext(AuthContext);
    const { showSnackbar, showDialog } = useContext(AppContext);

    const [ content, setContent ] = useState('');
    const [ files, setFiles ] = useState([]);
    const onSubmit = async (ticketId) => {
        if (!content) return showSnackbar('문의내용을 입력하세요.');

        for (let file of files) {
            if (file.size > 5 * 1024 * 1024) throw new Error('파일이 5MB 보다 클 수 없습니다.');
        }

        const data = new FormData();
        data.append('ticketId', ticketId);
        data.append('content', content);
        for (let file of files) {
            data.append('files', file);
        }
        
        await API.post('/ticket/letter', data);
        
        showSnackbar('글을 썼습니다.');
        setContent('');
        loadData();
    }

    const handleRemoveLetter = (letter) => {
        showDialog({
            title: '글 삭제',
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete('/ticket/letter/' + letter.id)
                    .then(() => {
                        showSnackbar('글을 삭제했습니다.');
                        loadData();
                    });
                }
            },
        });
    }

    const handleRespondSendAlarm = () => {
        showDialog({
            message: '사용자에게 답글이 전달되었음을 알리시겠습니까?',
            first: {
                text: '네',
                onClick: () => {
                    const body = {
                        userId: ticket.userId,
                        code: 'ticket_respond',
                        message: "문의하신글에 답변글이 전달되었습니다.",
                        data: {
                            ticketId: ticket.id
                        },
                    }
                    API.post('/alarm/alarm', body)
                    .then(() => { showSnackbar('답글이 전달되었음을 알렸습니다.'); })
                }
            },
            second: {
                text: '아니오'
            }
        });
    }

    const onCloseClick = (ticketId) => {
        API.delete('/ticket/close-ticket/' + ticketId)
        .then(() => {
            showSnackbar('종료했습니다.');
            loadData();
        }); 
    }

    const categoryName = useMemo(() => {
        let name = '';
        Object.entries(categories).forEach(([ key, value ]) => {
            if (key === ticket.category) name = value;
        })
        return name;
    }, [ categories, ticket ]);

	return (
        <Card style={{ flex: 1, marginTop: '16px' }}>
            <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3px' }}>
                    <Typography variant="h5" style={{ fontWeight: 'bold', color: colors.primary }}>{categoryName}</Typography>
                    <Typography variant="body1" style={{ color: ticket.state === 'closed' ? colors.error : colors.secondary }}>{globals.ticketStates.find(item => item.key === ticket.state).name}</Typography>
                </div>

                <Divider />
                {ticket.letters.map((letter, index) => <div key={letter.id} style={{ margin: '8px 0', backgroundColor: letter.userId === me.id ? '#e8f5e9' : '#e1f5fe', padding: '8px 12px', borderRadius: '4px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                            <Typography variant="body2" style={{ color: colors.textHint }}>{letter.user?.nickname}</Typography>
                            <Typography variant="body2" style={{ color: colors.textHint }}>{datetimePipe(letter.createdAt)}</Typography>
                        </div>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap'}}>{letter.content}</Typography>

                        <div style={{ marginTop: '16px', display: 'flex', alignItems: 'flex-end', flexDirection: 'row-reverse' }}>
                            {ticket.state === 'opened' && index === ticket.letters.length - 1 && letter.userId === me.id && <Button type="button" size="small" variant="outlined" style={{ color: colors.warn }} onClick={() => { handleRemoveLetter(letter) }}>삭제</Button>}
                            {letter.fileInfos.length > 0 && <div style={{ flex: 1 }}>
                                <Typography variant="subtitle2" style={{ color: colors.textSecondary }}>첨부파일</Typography>
                                {letter.fileInfos.map(fileInfo => <Fragment key={fileInfo.id}>
                                    <Link style={{ fontSize: '12px', marginRight: '8px' }} href={fileInfo.downloadURL} target="_blank">{fileNameFromPathPipe(fileInfo.filePath)}</Link>
                                </Fragment>)}
                            </div>}
                        </div>

                        
                    </div>
                </div>)}

                {ticket.state === 'opened' && <>
                    <Divider />
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '8px' }}>
                        <TextField
                            style={{ flex: 1, marginRight: '8px' }}
                            multiline
                            rows={4}
                            variant="outlined"
                            required={true}
                            placeholder={'답글을 입력하세요.'}
                            onChange={(event) => setContent(event.target.value)}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '8px' }}>
                                {files.length === 0 ? <>
                                    <input multiple type="file" style={{ display: 'none' }} id={`attachFile_${ticket.id}`} onChange={event => { setFiles(event.target.files) }} />
                                    <label htmlFor={`attachFile_${ticket.id}`}>
                                        <Button type="button" component="span" size="small" variant="outlined" style={{ color: colors.textSecondary }}>파일첨부</Button>
                                    </label>
                                </> : <>
                                    <Button type="button" component="span" size="small" variant="outlined" style={{ color: colors.textSecondary }} onClick={() => { setFiles([]); }}>{`첨부삭제(${files.length})`}</Button>
                                </>}
                            </div>
                            <Button type="button" size="small" variant="outlined" color="primary" style={{ color: colors.primary }} onClick={() => onSubmit(ticket.id)}>글쓰기</Button>
                        </div>
                    </div>

                    <div style={{ marginTop: '8px', display: 'flex' }}>
                        <Button type="button" size="small" variant="outlined" style={{ color: colors.error, borderColor: colors.error }} onClick={() => onCloseClick(ticket.id)}>문의 종료</Button>
                        <Button type="button" size="small" variant="outlined" style={{ marginLeft: '6px', color: colors.info, borderColor: colors.info }} onClick={handleRespondSendAlarm}>답변알림 보내기</Button>
                    </div>
                </>}
            </CardContent>
        </Card>
	);
}

export default Ticket;