import React from 'react';
import { Divider, Typography } from '@material-ui/core';


export default function PageTitle ({ children }) {
    return (
        <div style={{ marginBottom: '16px' }}>
            {/* <Typography variant="h4">{children}</Typography> */}

            <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '8px' }}>{children}</Typography>
            <Divider />
        </div>
    );
}