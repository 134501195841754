import React from 'react';
import { makeStyles, useTheme, TableFooter, TablePagination, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { datetimePipe, reviewCategoryPipe } from '../../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';



const useStyles = makeStyles((theme) => ({

}));

const ReviewTable = ({ rows, totalCount, rowsInPage, page, setPage }) => {
	const classes = useStyles();
	const theme = useTheme();

	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const reviewTargetPipe = (row) => {
        return row.bucket?.stuff?.item?.name;
    }

	return (
        <TableContainer component={Paper}>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
						<StyledTableCell align="left">{'구분'}</StyledTableCell>
						<StyledTableCell align="left">{'대상'}</StyledTableCell>
						<StyledTableCell align="left">{'사용자'}</StyledTableCell>
						<StyledTableCell align="left">{'별점'}</StyledTableCell>
						<StyledTableCell align="left">{'내용'}</StyledTableCell>
						<StyledTableCell align="left">{'작성일시'}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id}>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                            <StyledTableCell align="left">{reviewCategoryPipe(row.category)}</StyledTableCell>
                            <StyledTableCell align="left">{reviewTargetPipe(row)}</StyledTableCell>
							<StyledTableCell align="left">{row.user?.nickname} ({row.user?.id})</StyledTableCell>
							<StyledTableCell align="left">{row.stars}</StyledTableCell>
							<StyledTableCell align="left">{row.content}</StyledTableCell>
							<StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={7} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={7}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

export default ReviewTable;