import React, { useState } from 'react';
import { Box, makeStyles, IconButton, Collapse } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { numberFormatPipe } from 'pipes';


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function Row(props) {
    const { row } = props;
    const [ open, setOpen ] = useState(false);
    const classes = useRowStyles();


    return (
        <>
            <TableRow className={classes.root}>
                <TableCell align="left" component="th" scope="row">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.type === 'checkbox' ? '복수선택' : '단일선택' }</TableCell>
                <TableCell align="left">{row.required ? '필수' : '-'}</TableCell>
                <TableCell align="left">{row.order}</TableCell>
                <TableCell align="left">{row.options.length}</TableCell>
                <TableCell align="center">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>이름</TableCell>
                                        <TableCell align="right">가격(원)</TableCell>
                                        <TableCell align="right">순서</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.options.map((option) => (
                                        <TableRow key={option.id}>
                                            <TableCell component="th" scope="row">{option.name}</TableCell>
                                            <TableCell align="right">{numberFormatPipe(option.price)}</TableCell>
                                            <TableCell align="right">{option.order}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}






