import React, { useState, useContext, useEffect } from 'react';
import { Typography, makeStyles, useTheme, Button, Link, TextField, Paper, Divider, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from 'components/Loading';
import colors from 'appcolors';
import { useParams } from 'react-router-dom';
import API from 'api';
import Guide from 'components/Guide';
import { AppContext } from 'contexts/app.context';
import CircularButton from 'components/CircularButton';


const FormValidationSchema = Yup.object({
    code: Yup.string().required('필수입력입니다.'),
});

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        marginBottom: '8px',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
}));

export default function ColumnFormPage() {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();

    const { id } = useParams();
    const { showSnackbar, showAlert, showDialog } = useContext(AppContext);

    const [ columnCodes, setColumnCodes ] = useState();
    const [ selectedCode, setSelectedCode ] = useState();
    useEffect(() => {
        API.get('/column/column-codes')
        .then(setColumnCodes);
    }, []);

    // 칼럼 정보
    const [ column, setColumn ] = useState();
    useEffect(() => {
        if (id && columnCodes) fetchColumn();
    }, [ id, columnCodes ]);
    const fetchColumn = () => {
        API.get('/column/column/' + id)
        .then((data) => {
            setColumn(data);
            setSelectedCode(columnCodes.find(item => item.code === data.code));
        });
    }
    // end: 칼럼 정보

    const handleAdd = (values, { setSubmitting, resetForm }) => {
        API.post('/column/column', values)
        .then((column) => {
            showSnackbar('등록했습니다.');
            history.push('/column/form/' + column.id);
        })
        .finally(() => { setSubmitting(false) });
    }

    const handleModify = (values, { setSubmitting, resetForm }) => {
        API.put('/column/column', { ...values, id })
        .then(() => {
            showSnackbar('수정했습니다.');
        })
        .finally(() => { setSubmitting(false) });
    }

    const handleRemove = () => {
        showDialog({
            title: '칼럼삭제',
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete('/column/column/' + column.id)
                    .then(() => {
                        showSnackbar('삭제했습니다.');
                        history.push('/column/list');
                    });
                }
            },
        });
    }

    // 사진
    const [ imageURL, setImageURL ] = useState('');
    const handleImageUrlChange = (event) => {
        setImageURL(event.target.value);
    }
    const handleAddImageURL = () => {
        if (!imageURL) return showSnackbar('URL 을 입력하세요.');
        if (column.image) return showAlert('먼저 기존의 이미지를 삭제하세요.');

        const fileInfo = {
            category: 'column',
            refId: column.id,
            downloadURL: imageURL,
            type: 'image',
        };
        API.post('/common/append-fileinfo', fileInfo)
        .then(fileInfo => {
            setColumn(column => {
                const result = {...column};
                result.image = fileInfo;
                return result;
            })
            setImageURL('');
        });
    }

    const [ file, setFile ] = useState();
    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    }

    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const handleAddFileInfo = () => {
        if (column.image) return showAlert('먼저 기존의 이미지를 삭제하세요.');

        if (file) {
            const data = new FormData();
            data.append('file', file);
            data.append('category', 'column');
            data.append('refId', column.id);
            data.append('withThumbnail', false);

            setFileUploadLoading(true);
            API.post('/common/append-file', data)
            .then(fileInfo => {
                fetchColumn();
                setFile(null);
            })
            .finally(() => { setFileUploadLoading(false) });
        }
    }

    const handleRemoveFile = () => {
        API.delete('/common/remove-file/' + column.image.id)
        .then(() => {
            fetchColumn();
            showSnackbar('파일을 삭제했습니다.');
        });
    }
    // end: 사진

    // 모바일사진
    const [ mobileImageURL, setMobileImageURL ] = useState('');
    const handleMobileImageUrlChange = (event) => {
        setMobileImageURL(event.target.value);
    }
    const handleAddMobileImageURL = () => {
        if (!mobileImageURL) return showSnackbar('URL 을 입력하세요.');
        if (column.mobileImage) return showAlert('먼저 기존의 이미지를 삭제하세요.');

        const fileInfo = {
            category: 'column_mobile',
            refId: column.id,
            downloadURL: mobileImageURL,
            type: 'image',
        };
        API.post('/common/append-fileinfo', fileInfo)
        .then(fileInfo => {
            setColumn(column => {
                const result = {...column};
                result.mobileImage = fileInfo;
                return result;
            })
            setMobileImageURL('');
        });
    }

    const [ mobileFile, setMobileFile ] = useState();
    const handleMobileFileChange = (e) => {
        if (e.target.files[0]) {
            setMobileFile(e.target.files[0]);
        }
    }

    const [mobileFileUploadLoading, setMobileFileUploadLoading] = useState(false);
    const handleAddMobileFileInfo = () => {
        if (column.mobileImage) return showAlert('먼저 기존의 이미지를 삭제하세요.');

        if (mobileFile) {
            const data = new FormData();
            data.append('file', mobileFile);
            data.append('category', 'column_mobile');
            data.append('refId', column.id);
            data.append('withThumbnail', false);

            setMobileFileUploadLoading(true);
            API.post('/common/append-file', data)
            .then(fileInfo => {
                fetchColumn();
                setMobileFile(null);
            })
            .finally(() => { setMobileFileUploadLoading(false) });
        }
    }

    const handleRemoveMobileFile = () => {
        API.delete('/common/remove-file/' + column.mobileImage.id)
        .then(() => {
            fetchColumn();
            showSnackbar('파일을 삭제했습니다.');
        });
    }
    // end: 모바일사진

    if (!columnCodes) return (<Loading />);
    if (id && !column) return (<Loading />);

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        code: column && column.code || '',
                        title: column && column.title || '',
                        url: column && column.url || '',
                        order: column && column.order || 1,
                        visible: column && column.visible,
                    }}
                    validationSchema={FormValidationSchema}
                    onSubmit={id ? handleModify : handleAdd}
                >
                    {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                            <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '8px' }}>{id ? '칼럼수정' : '칼럼등록'}</Typography>
                            <Divider />

                            <form onSubmit={handleSubmit}>
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>구분 *</Typography>
                                    <TextField
                                        select
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        label={'구분'}
                                        required={true}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            const code = columnCodes.find(item => item.code === value);
                                            
                                            setFieldValue('code', value);
                                            
                                            setSelectedCode(code);
                                        }}
                                        onBlur={handleBlur('code')}
                                        error={!!(touched.code && errors.code)}
                                        value={values.code}
                                    >
                                        {columnCodes.map(codeInfo => <MenuItem key={codeInfo.code} value={codeInfo.code}>{codeInfo.name}</MenuItem>)}
                                    </TextField>
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>타이틀</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        required={true}
                                        onChange={handleChange('title')}
                                        onBlur={handleBlur('title')}
                                        helperText={(touched.title && errors.title) || ' '}
                                        error={!!(touched.title && errors.title)}
                                        value={values.title}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>URL (클릭시 링크)</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChange('url')}
                                        onBlur={handleBlur('url')}
                                        helperText={(touched.url && errors.url) || ' '}
                                        error={!!(touched.url && errors.url)}
                                        value={values.url}
                                    />
                                </div>

                                {column && <>
                                    {/* image */}
                                    <div className={classes.section}>
                                        <Typography variant="subtitle2" className={classes.label}>사진</Typography>
                                        {column.image && <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '16px' }}>
                                            <img src={column.image.downloadURL} style={{ marginRight: '8px' }} />
                                            <Button variant="outlined" color="secondary" size="small" onClick={handleRemoveFile}>이미지 삭제</Button>
                                        </div>}
                                        <div style={{ border: '1px solid #dddddd', padding: '4px' }}>
                                            <input type="file" accept="image/*" onChange={handleFileChange} />
                                            {file && <CircularButton type="button" variant="outlined" size="small" loading={fileUploadLoading} onClick={handleAddFileInfo}>업로드</CircularButton>}
                                        </div>

                                        <div style={{ marginTop: '8px', display: 'flex', width: '100%' }}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.input}
                                                style={{ flex: 1, marginRight: '8px' }}
                                                size="small"
                                                onChange={handleImageUrlChange}
                                            />
                                            <Button variant="outlined" size="small" color="secondary" onClick={handleAddImageURL}>이미지 URL 추가</Button>
                                        </div>

                                        {selectedCode && <Typography variant="body2">추천 이미지 사이즈: {`${selectedCode.size.width} x ${selectedCode.size.height}`} </Typography>}
                                    </div>

                                    {/* mobileImage */}
                                    <div className={classes.section}>
                                        <Typography variant="subtitle2" className={classes.label}>모바일사진</Typography>
                                        {column.mobileImage && <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '16px' }}>
                                            <img src={column.mobileImage.downloadURL} style={{ marginRight: '8px' }} />
                                            <Button variant="outlined" color="secondary" size="small" onClick={handleRemoveMobileFile}>이미지 삭제</Button>
                                        </div>}
                                        <div style={{ border: '1px solid #dddddd', padding: '4px' }}>
                                            <input type="file" accept="image/*" onChange={handleMobileFileChange} />
                                            {mobileFile && <CircularButton type="button" variant="outlined" size="small" loading={mobileFileUploadLoading} onClick={handleAddMobileFileInfo}>업로드</CircularButton>}
                                        </div>

                                        <div style={{ marginTop: '8px', display: 'flex', width: '100%' }}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.input}
                                                style={{ flex: 1, marginRight: '8px' }}
                                                size="small"
                                                onChange={handleMobileImageUrlChange}
                                            />
                                            <Button variant="outlined" size="small" color="secondary" onClick={handleAddMobileImageURL}>이미지 URL 추가</Button>
                                        </div>
                                    </div>

                                    {/* order */}
                                    <div className={classes.section}>
                                        <Typography variant="subtitle2" className={classes.label}>순번</Typography>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            className={classes.input}
                                            required={true}
                                            onChange={handleChange('order')}
                                            onBlur={handleBlur('order')}
                                            helperText={(touched.order && errors.order) || '순번이 낮을수록 먼저 출력됩니다.'}
                                            error={!!(touched.order && errors.order)}
                                            value={values.order}
                                        />
                                        <Typography variant="body2"></Typography>
                                    </div>

                                    {/* visible */}
                                    <div className={classes.section}>
                                        <Typography variant="subtitle2" className={classes.label}>노출여부</Typography>
                                        <TextField
                                            select
                                            style={{ width: '100%' }}
                                            variant="outlined"
                                            label={'노출여부'}
                                            required={true}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                setFieldValue('visible', value);
                                            }}
                                            onBlur={handleBlur('visible')}
                                            error={!!(touched.visible && errors.visible)}
                                            value={values.visible}
                                        >
                                            <MenuItem value={true}>{'노출'}</MenuItem>
                                            <MenuItem value={false}>{'미노출'}</MenuItem>
                                        </TextField>
                                    </div>
                                </>}


                                <div style={{ marginTop: '24px' }}>
                                    <Button type="button" style={{ marginRight: '8px' }} variant="contained" color="secondary" onClick={() => { history.push('/column/list') }}>목록</Button>
                                    {id && <>
                                        <Button type="button" style={{ marginRight: '8px', backgroundColor: 'red', color: 'white' }} variant="contained" onClick={handleRemove}>삭제</Button>
                                    </>}
                                    <Button type="submit" variant="contained" color="primary">{id ? '수정' : '등록'}</Button>
                                </div>

                                {!id && <div style={{ marginTop: '16px'}}>
                                    <Guide>칼럼의 이미지 또는 속성은 먼저 등록후 편집 가능합니다.</Guide>
                                </div>}
                            </form>
                        </>
                    )}
                </Formik>
            </Paper>
        </div>
	);
}
