import React from 'react';
import { Paper } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import ItemSearch from '../search/ItemSearch';



export default function ItemListPage() {
	return (
		<div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>제품</PageTitle>
                
                <ItemSearch intend="list" />
            </Paper>
		</div>
	);
}
