import React, { useState } from 'react';
import { makeStyles, Button, Switch, Dialog, DialogContentText, DialogContent, DialogActions, Typography, TextField, Checkbox, } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { numberFormatPipe } from 'pipes';
import API from 'api';
import { useContext } from 'react';
import { AppContext } from 'contexts/app.context';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';
import colors from 'appcolors';
import * as yup from 'yup';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
    },
    section: {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
    color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        marginBottom: '8px',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
}));

const ProxyItem = ({ row, item, loadProxy }) => {
    const { showSnackbar } = useContext(AppContext);

    const classes = useStyles();

    const [ modalOpen, setModalOpen ] = useState(false);

    const handleRemove = () => {
        API.delete('/option/proxy/' + row.id)
        .then(() => {
            showSnackbar('삭제했습니다.');
            loadProxy();
        });
    }

    const toggleExpose = () => {
        const data = {
            ...row,
            expose: !row.expose,
        };
        API.put('/option/proxy', data)
        .then(() => {
            showSnackbar('적용했습니다.');
            loadProxy();
        });
    }

    const toggleStockout = () => {
        const body = {
            ...row,
            stockCount: row.stockCount === 0 ? null : 0
        }

        API.put('/item/proxy', body)
        .then(() => {
            showSnackbar('수정했습니다.');
            loadProxy();
        });
    }

    // plusEvent
    const [ eventPrice, setEventPrice ] = useState(row.plusEventPrice || '');
    const [ doEvent, setDoEvent ] = useState(row.plusEventPrice !== null);

    const handlePlusEvent = async () => {
        try {
            if (doEvent) {
                await yup.number().required().integer().validate(eventPrice)
                .catch(() => { 
                    throw new Error('가격입력란이 올바르지 않습니다.')
                });
            }
    
            const body = {
                ...row,
                plusEventPrice: doEvent ? eventPrice : null
            };
            API.put('/option/proxy', body)
            .then(() => {
                showSnackbar('적용했습니다.');
                loadProxy();
            });
        }
        catch(error) {
            showSnackbar(error.message);
        }
    };
    // end: plusEvent

    return (<>
        <StyledTableRow>
            <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
            <StyledTableCell align="left">{row.proxyOptions.map(po => po.option?.name).join(',')}</StyledTableCell>
            <StyledTableCell align="left">{numberFormatPipe(row.price || item.price)}</StyledTableCell>
            <StyledTableCell align="left">{numberFormatPipe(row.normalPrice || item.normalPrice) || '-'}</StyledTableCell>
            <StyledTableCell align="left">{row.stockCount === 0 ? '품절' : '-'}</StyledTableCell>
            <StyledTableCell align="left">{row.plusEventPrice !== null ? numberFormatPipe(row.plusEventPrice) : '-' }</StyledTableCell>
            <StyledTableCell align="left">
                <Switch checked={row.expose} onChange={toggleExpose} color={'primary'} />
            </StyledTableCell>
            <StyledTableCell align="center">
                <Button variant="outlined" size="small" onClick={handleRemove}>삭제</Button>
                <Button variant="outlined" size="small" style={{ margin: '0 4px' }} onClick={() => { setModalOpen(true) }}>1+1 이벤트</Button>
                {<Button variant="outlined" size="small" onClick={toggleStockout}>품절상태 변경</Button>}
            </StyledTableCell>
        </StyledTableRow>

        {/* PlusEventPrice */}
        <Dialog
            open={modalOpen}
            onClose={() => { setModalOpen(false); }}
        >
            <DialogContent style={{ minWidth: '400px' }}>
                <DialogContentText>{'1+1 이벤트 설정'}</DialogContentText>

                <div className={classes.section}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">{'이벤트 오픈'}</Typography>
                        <Checkbox checked={doEvent} onChange={(event) => setDoEvent(val => !val)} />
                    </div>
                </div>

                <div className={classes.section}>
                    <Typography variant="subtitle2" className={classes.label}>가격설정</Typography>
                    <TextField
                        style={{ width: '100%' }}
                        size='small'
                        variant="outlined"
                        label={'가격설정'}
                        onChange={event => { setEventPrice(event.target.value); }}
                        value={eventPrice}
                    >
                    </TextField>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setModalOpen(false);
                }} color="secondary">{'취소'}</Button>

                <Button onClick={() => {
                    setModalOpen(false);
                    handlePlusEvent();
                }} color="primary" autoFocus>{'확인'}</Button>
            </DialogActions>
        </Dialog>
    </>);
}


export default function ProxyTableView({ rows, item, loadProxy }) {
	const classes = useStyles();
    
	return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>{'일련번호'}</StyledTableCell>
                        <StyledTableCell align="left">{'옵션'}</StyledTableCell>
                        <StyledTableCell align="left">{'판매가'}</StyledTableCell>
                        <StyledTableCell align="left">{'정상가'}</StyledTableCell>
                        <StyledTableCell align="left">{'품절여부'}</StyledTableCell>
                        <StyledTableCell align="left">{'1+1 이벤트'}</StyledTableCell>
                        <StyledTableCell align="left">{'노출유무'}</StyledTableCell>
                        <StyledTableCell align="center">{'관리'}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => <ProxyItem key={row.id} row={row} item={item} loadProxy={loadProxy} />)}
                </TableBody>
            </Table>
        </TableContainer>      
	);
}
