import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import { Container, Box, Grid, Typography, makeStyles, Button, Link, TextField, Paper, Checkbox, useTheme, Dialog, DialogContent, DialogActions, DialogContentText, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import colors from 'appcolors';
import PageTitle from 'components/PageTitle';
import useGetInquiries from './useGetInquiries';
import InquiryTableView from './InquiryTableView';
import { datetimePipe } from 'pipes';
import API from 'api';
import { AppContext } from 'contexts/app.context';



const useStyles = makeStyles((theme) => ({

}));

const InquiryPage = () => {
    const theme = useTheme();
	const classes = useStyles();
	const history = useHistory();

    // parameters
    const [ page, setPage ] = useState(0);
    const [ keyword, setKeyword ] = useState('');
    const [ state, setState ] = useState('reported');
    const [ trigger, setTrigger ] = useState();

    const { showSnackbar } = useContext(AppContext);

    const { 
        rows,
        loading,
        mayMore,
        totalCount,
        rowsInPage,
    } = useGetInquiries(keyword, state, page, trigger);
    

    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
        setPage(0);
    }

    const [ replyModalOpen, setReplyModalOpen ] = useState(false);
    const [ reply, setReply ] = useState();
    const inquiryRef = useRef();
    const handleReplyClick = (inquiry) => {
        inquiryRef.current = inquiry;
        setReplyModalOpen(true);
    }
    const handleReply = () => {
        setReplyModalOpen(false);
        if (inquiryRef.current) {
            const data = {
                inquiryId: inquiryRef.current.id,
                comment: reply,
            };
            API.post('/admin/reply-inquiry', data)
            .then(() => {
                showSnackbar('답변했습니다.');
                setTrigger(new Date().getTime());
            });
        }
    }

    const [ inquiry, setInquiry ] = useState();
    const [ inquiryModalOpen, setInquiryModalOpen ] = useState(false);
    const handleShowDetailClick = (inquiry) => {
        setInquiry(inquiry);
        setInquiryModalOpen(true);
    }

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>이메일 문의</PageTitle>

                <div style={{ marginTop: '24px', display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="키워드"
                        variant="outlined"
                        required={false}
                        onChange={handleKeywordChange}
                        value={keyword}
                    />

                    <div style={{ flex: 1 }} />
                    
                    <Checkbox
                        checked={state === 'reported'}
                        onChange={event => { 
                            if (event.target.checked) {
                                setState('reported');
                            }
                            else {
                                setState(null);
                            }
                        }}
                    />
                    <Typography variant="body1">미답변 문의만 보기</Typography>
                </div>

                {!loading ? <div style={{ marginTop: theme.spacing(4) }}>
                    <InquiryTableView
                        loading={loading}
                        rows={rows} 
                        totalCount={totalCount} 
                        rowsInPage={rowsInPage} 
                        page={page} 
                        setPage={setPage} 
                        handleReplyClick={handleReplyClick}
                        handleShowDetailClick={handleShowDetailClick}
                    />
                </div> : <Loading />}
            </Paper>

            {inquiry && <Dialog
                open={inquiryModalOpen}
                onClose={() => { setInquiryModalOpen(false); }}
            >
                <DialogContent>
                    <div style={{ minWidth: '400px' }}>
                        <Typography variant='body1' style={{ color: colors.textSecondary }}>문의내용</Typography>
                        <Typography variant='body2' style={{ marginTop: '4px', whiteSpace: 'pre-line' }}>{inquiry.comment}</Typography>
                        {inquiry.state === 'replied' && <>
                            <Divider style={{ margin: '8px 0'}} />
                            <Typography variant='body1' style={{ color: colors.textSecondary }}>답변내용 ({datetimePipe(inquiry.repliedAt)})</Typography>
                            <Typography variant='body2' style={{ marginTop: '4px', whiteSpace: 'pre-line' }}>{inquiry.reply}</Typography>
                        </>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setInquiryModalOpen(false); }} color="primary" autoFocus>확인</Button>
                </DialogActions>
            </Dialog>}

            <Dialog
                open={replyModalOpen}
                onClose={() => { setReplyModalOpen(false); }}
            >
                <DialogContent>
                    <div style={{ minWidth: '360px' }}>
                        <TextField 
                            variant="outlined" 
                            placeholder='답변을 입력하세요.'
                            multiline={true}
                            rows={4}
                            style={{ width: '100%' }}
                            onChange={(e) => { setReply(e.target.value) }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReply} color="primary" autoFocus>확인</Button>
                </DialogActions>
            </Dialog>
        </div>
	);
}

export default InquiryPage;