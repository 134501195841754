import React from 'react';
import { makeStyles, useTheme, Button, } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
    },
}));

export default function ColumnTableView({ rows, columnCodes }) {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<>
			<TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{'일련번호'}</StyledTableCell>
                            <StyledTableCell align="left">{'구분'}</StyledTableCell>
                            <StyledTableCell align="left">{'타이틀'}</StyledTableCell>
                            <StyledTableCell align="left">{'URL'}</StyledTableCell>
                            <StyledTableCell align="left">{'순서'}</StyledTableCell>
                            <StyledTableCell align="left">{'노출여부'}</StyledTableCell>
                            <StyledTableCell align="center">{'관리'}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                                <StyledTableCell align="left">{columnCodes.find(item => item.code === row.code).name}</StyledTableCell>
                                <StyledTableCell align="left">{row.title}</StyledTableCell>
                                <StyledTableCell align="left">{row.url}</StyledTableCell>
                                <StyledTableCell align="left">{row.order}</StyledTableCell>
                                <StyledTableCell align="left">{row.visible ? 'O' : 'X'}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button variant="outlined" size="small" component={RouterLink} to={`/column/form/${row.id}`}>수정</Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
		</>
	);
}
