import React, { useContext, useRef, useState } from 'react';
import { useTheme, Button, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import { CSVLink } from "react-csv";
import Loading from 'components/Loading';
import useBucketSearch from '../useBucketSearch';
import PageTitle from 'components/PageTitle';
import WaitingBucketTable from './WaitingBucketTable';
import colors from 'appcolors';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import moment from 'moment';
import OrderDetail from 'views/order/detail/OrderDetail';


export default function WaitingBucketPage() {
	const theme = useTheme();
    const { showSnackbar, showAlert } = useContext(AppContext);
    
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useBucketSearch('waiting', page, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    // ready
    const handleReady = (id) => () => {
		const data = { bucketIds: [ id ] };
		API.put('/bucket/make-buckets-ready', data)
		.then(() => {
			showSnackbar('발송준비 되었습니다.');
            handleRefresh();
		});
	}
    const [ checked, setChecked ] = useState([]);
    const handleReadySelected = () => {
        if (checked.length === 0) return showAlert('하나 이상 선택해주세요.');
        
        const data = { bucketIds: checked };
		API.put('/bucket/make-buckets-ready', data)
		.then(() => {
			showSnackbar('발송준비 되었습니다.');
            handleRefresh();
		});
    }

    // cancel
    const [ cancelModalOpen, setCancelModalOpen ] = useState(false);
    const [ reason, setReason ] = useState('');
    const cancelIdRef = useRef();
    const handleCancelClick = (id) => () => {
        cancelIdRef.current = id;
        setCancelModalOpen(true);
    }
    const handleCancel = () => {
        setCancelModalOpen(false);
        const data = {
            bucketId: cancelIdRef.current,
            reason,
        };
        API.put('/bucket/make-bucket-canceled', data)
        .then(() => {
            showSnackbar('취소되었습니다.');
            handleRefresh();
        })
    }
    // end: cancel

    // order info
    const [ orderModalOpen, setOrderModalOpen ] = useState(false);
    const [ orderId, setOrderId ] = useState();
    const handleOrderInfo = (orderId) => () => {
        setOrderId(orderId);
        setOrderModalOpen(true);
    }
    // end: order info

    // csv
    const [ csvHeader, setCsvHeader ] = useState([]);
    const [ csvData, setCsvData ] = useState([]);
    const handleCSV = (event, done) => {
        const headEl = document.querySelector('tr.content-head');
        const bodyEl = document.querySelectorAll('tr.content-body');

        const head = [];
        for (let td of headEl.children) {
            if (td.classList.contains('content-exception')) continue;
            head.push(td.innerHTML);
        }

        const body = [];
        for (let tr of bodyEl) {
            // if (!tr.children[0]?.children[0]?.checked) continue;
            const row = [];
            for (let td of tr.children) {
                if (td.classList.contains('content-exception')) continue;
                
                let text = '';
                
                const target = td.querySelector('.data-target');
                if (target) {
                    text = target.innerHTML;
                }
                else {
                    const node = td.childNodes[0];
                    if (node) {
                        if (target) {
                            text = target.innerHTML;
                        }
                        else if (node.nodeType === Node.TEXT_NODE) {
                            text = td.innerHTML;
                        }
                        else {
                            text = node.innerHTML;
                        }
                        text = text.trim();
                        text = text.replaceAll('&nbsp;', ' ');
                    }			
                }
                
                row.push(text);
            }
            body.push(row);
        }

        setCsvHeader(head);
        setCsvData(body);
        
        done(true);
    }
    // end: csv

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>확인대기</PageTitle>
                <Typography style={{ color: colors.textSecondary }}>* 결제완료된 내역입니다.</Typography>
                <Typography style={{ color: colors.textSecondary }}>* 발송준비가 되면 구매자는 주문을 취소할 수 없습니다.</Typography>
                <Typography style={{ color: colors.textSecondary }}>* 발송준비시 구매자에게 문자메시지를 보내지 않습니다.</Typography>
                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                        <CSVLink
                            headers={csvHeader}
                            data={csvData}
                            className={"MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"}
                            style={{ marginLeft: '4px' }}
                            filename={`mnx15_${moment().format('YYYY-MM-DD')}`}
                            asyncOnClick={true}
                            onClick={handleCSV}
                        >내려받기</CSVLink>
                        <Button variant="contained" style={{ marginLeft: '4px' }} color="primary" onClick={handleReadySelected}>선택 발송준비</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <WaitingBucketTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage} 
                            handleReady={handleReady}
                            handleCancel={handleCancelClick}
                            checked={checked}
                            setChecked={setChecked}
                            handleOrderInfo={handleOrderInfo}
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>

            {/* cancel modal */}
            <Dialog open={cancelModalOpen} onClose={() => { setCancelModalOpen(false); }}>
                <DialogTitle id="form-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText>{'배송 대상에서 제거됩니다.\n환불이 되어있지 않았을 경우 환불됩니다.\n사용자는 알림을 받습니다.\n취소이유를 간단히 입력하세요. (예: 재고없음)'}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="이유"
                        fullWidth
                        value={reason}
                        onChange={event => { setReason(event.target.value); }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCancelModalOpen(false)} color="primary">취소</Button>
                    <Button onClick={handleCancel} color="primary">확인</Button>
                </DialogActions>
            </Dialog>

            {/* order info */}
            <Dialog fullScreen open={orderModalOpen} onClose={() => { setOrderModalOpen(false); }}>
                <DialogTitle>주문정보</DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: '8px', display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button variant='contained' color="primary" onClick={() => { setOrderModalOpen(false); }}>닫기</Button>
                    </div>
                    {orderId && <OrderDetail orderId={orderId} />}
                </DialogContent>
            </Dialog>
        </div>
	);
}