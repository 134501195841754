module.exports = {
    iamport: {
        id: 'imp65274246',
    },

    ticketCategories: [
        { key: 'claim', name: '클레임' },
        { key: 'service', name: '서비스' },
        { key: 'bug', name: '버그' },
    ],

    ticketStates: [
        { key: 'opened', name: '해결진행중' },
        { key: 'closed', name: '종료됨' },
    ],

    payOptions: [
        { key: 'onetime', name: '일시불' },
        { key: 'subscription', name: '정기결제' },
    ],

    deliveryTerms: [
        { key: '2', name: '2달' },
        { key: '3', name: '3달' },
        { key: '4', name: '4달' },
    ],
}