import React, { useContext } from 'react';
import { Typography, makeStyles, useTheme, Button, TextField, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import colors from '../../../appcolors';
import CircularButton from 'components/CircularButton';
import API from 'api';
import { AppContext } from 'contexts/app.context';



const SelectFormValidationSchema = Yup.object({
    name: Yup.string().required('필수입력입니다.'),
});

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '16px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    label: {
        color: colors.textSecondary, 
        margin: '16px 0 8px 0',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
}));

export default function SelectFormPage() {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();

    const { itemId } = useParams();

    const { showSnackbar } = useContext(AppContext);

    const handleAdd = (values, actions) => {
        API.post('/option/select', values)
        .then(() => {
            showSnackbar('셀렉션을 추가했습니다.');
            history.push('/item/' + itemId + '/options')
        })
        .finally(() => { actions.setSubmitting(false); })
    }

	return (
        <div className="pageMargin">
            <h3>셀렉션 추가</h3>
            <Paper className="pagePadding">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        itemId,
                        name: '',
                    }}
                    validationSchema={SelectFormValidationSchema}
                    onSubmit={handleAdd}
                >
                    {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label} style={{ marginTop: 0 }}>이름 *</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        size="small"
                                        required={true}
                                        onChange={handleChange('name')}
                                        onBlur={handleBlur('name')}
                                        helperText={(touched.name && errors.name) || ' '}
                                        error={!!(touched.name && errors.name)}
                                        value={values.name}
                                    />
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <Button variant="contained" color="primary" onClick={() => { history.push('/item/' + itemId + '/options') }}>목록</Button>
                                    <CircularButton variant="contained" color="secondary" style={{ marginLeft: '8px' }} onClick={handleSubmit} loadig={isSubmitting}>등록</CircularButton>
                                </div>
                            </form>
                        </>
                    )}
                </Formik>
            </Paper>
        </div>
	);
}
