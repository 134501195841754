import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import { Container, Box, Grid, Typography, makeStyles, Button, Link, TextField, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import PageTitle from 'components/PageTitle';
import colors from 'appcolors';
import API from 'api';
import moment from 'moment';
import VisitCountChart from './VisitCountChart';



const useStyles = makeStyles((theme) => ({

}));

const VisitCount = ({ yearmonth }) => {

    const [ data, setData ] = useState();
    useEffect(() => {
        const date = moment(yearmonth, 'YYYY-MM');
        const params = { year: date.year(), month: date.month()};
        API.get('/statistics/daily-visit-count', { params })
        .then(data => {
            setData(data);
        })
    }, [ yearmonth ]);

    if (!data) return null;

	return (
        <div>
            <VisitCountChart
                dataset={data}
            />
        </div>
	);
}

export default VisitCount;