import React, { useState, useContext } from 'react';
import { Button, Paper, useTheme, Dialog, DialogContent, DialogContentText, DialogActions, TextField, Typography } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import useApi from 'hooks/useApi';
import API from 'api';
import { useParams } from 'react-router-dom';
import ProxyTableView from './ProxyTableView';
import { AppContext } from 'contexts/app.context';
import Loading from 'components/Loading';



export default function Proxies({ trigger }) {
    const theme = useTheme();
    
    const { itemId } = useParams();
    const { showSnackbar, showDialog } = useContext(AppContext);

    // proxies
    const [ rows, setRows ] = useState();
    useApi(() => {
        if (itemId) loadProxy();
    }, [ itemId, trigger ]);
    const loadProxy = () => {
        API.get(`/option/proxies/${itemId}`)
        .then(setRows);
    }

    // item
    const [ item, setItem ] = useState();
    useApi(() => {
        if (itemId) {
            API.get(`/item/item-by-id/${itemId}`)
            .then(setItem);
        }
    }, [ itemId ])

    // AddProxiesByOptions
    const handleAddProxiesByOptions= () => {
        const data = { itemId };
        API.post('/option/add-proxies-by-options', data)
        .then(() => {
            showSnackbar('프록시를 추가했습니다.');
            loadProxy();
        })
    }

    const [ plusEventModalOpen, setPlusEventModalOpen ] = useState(false);
    const [ plusEventPrice, setPlusEventPrice ] = useState('');
    const handleTurnintoPlusEventAll = () => {
        const body = { 
            itemId: item.id,
            plusEventPrice: plusEventPrice,
        };
        API.put('/option/turninto-plus-event', body)
        .then(() => {
            showSnackbar('적용했습니다.');
            loadProxy();
        })
    }

    const handleTurnoutPlusEventAll = () => {
        showDialog({
            message: '모든 프록시의 이벤트 적용을 해제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    const body = { 
                        itemId: item.id,
                    };
                    API.put('/option/turnout-plus-event', body)
                    .then(() => {
                        showSnackbar('적용했습니다.');
                        loadProxy();
                    });
                }
            },
            second: {
                text: '취소',
            }
        });
    }
    
    if (!(item && rows)) return (<Loading />);

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>프록시</PageTitle>

                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end', marginBottom: '8px' }}>
                    <Button variant="contained" color="primary" onClick={handleAddProxiesByOptions}>가능한 모든 프록시 생성</Button>
                    <Button variant='contained' color="secondary" style={{ margin: '0 8px' }} onClick={() => { setPlusEventModalOpen(true); }}>모든프록시 1+1 이벤트 적용</Button>
                    <Button variant='contained' color="danger" onClick={handleTurnoutPlusEventAll}>모든프록시 1+1 이벤트 적용해제</Button>
                </div>

                <div style={{ marginTop: theme.spacing(4) }}>
                    <ProxyTableView
                        rows={rows} 
                        item={item}
                        loadProxy={loadProxy}
                    />
                </div>

                {/* PlusEventPrice */}
                <Dialog
                    open={plusEventModalOpen}
                    onClose={() => { setPlusEventModalOpen(false); }}
                >
                    <DialogContent style={{ minWidth: '400px' }}>
                        <DialogContentText>{'1+1 이벤트 설정'}</DialogContentText>

                        <div>
                            <Typography variant="subtitle2">가격설정</Typography>
                            <TextField
                                style={{ width: '100%' }}
                                size='small'
                                variant="outlined"
                                label={'가격설정'}
                                onChange={event => { setPlusEventPrice(event.target.value); }}
                                value={plusEventPrice}
                            >
                            </TextField>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setPlusEventModalOpen(false);
                        }} color="secondary">{'취소'}</Button>

                        <Button onClick={() => {
                            setPlusEventModalOpen(false);
                            handleTurnintoPlusEventAll();
                        }} color="primary" autoFocus>{'확인'}</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </div>
	);
}
