import React, { useContext, useState, useRef } from 'react';
import { useTheme, Button, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Typography } from '@material-ui/core';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import useClaimSearch from '../useClaimSearch';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import ReturnClaimTable from './ReturnClaimTable';
import colors from 'appcolors';
import OrderDetail from 'views/order/detail/OrderDetail';


const ReturnClaimPage = () => {
	const theme = useTheme();
    
    const { showDialog, showSnackbar } = useContext(AppContext);
    const [ page, setPage ] = useState(0);
    const [ state, setState ] = useState();
    const [ userId, setUserId ] = useState();
    const [ trigger, setTrigger ] = useState();

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useClaimSearch('return', state, userId, page, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    // 거절
    const [ rejectModalOpen, setRejectModalOpen ] = useState(false);
    const [ reply, setReply ] = useState('');
    const claimIdRef = useRef();
    const onRejectClick = (claimId) => () => {
        claimIdRef.current = claimId;
        setReply('');
        setRejectModalOpen(true);
    }
    const handleReject = () => {
        setRejectModalOpen(false);
        const data = { 
            claimId: claimIdRef.current,
            state: 'rejected',
            reply,
        };
        API.put('/claim/reject-claim', data)
        .then(() => {
            showSnackbar('거절되었습니다.');
            handleRefresh();
        })
    }
    // end: 거절

    // 수거진행중
    const onRetrievingClick = (claimId) => () => {
        const dialogData = {
            title: '수거진행중',
            message: '반품요청을 수락하고, 수거진행중 상태로 처리하시겠습니까?',
            first: {
                onClick: () => { handleRetrieving(claimId); }
            },
            second: true,
        }
        showDialog(dialogData);
    }
    const handleRetrieving = (claimId) => {
        const data = { claimId };
        API.put('/claim/make-claim-retrieving', data)
        .then(() => {
            showSnackbar('수거진행중으로 상태가 되었습니다.');
            handleRefresh();
        })
    }
    // end: 수거진행중

    // 수거완료
    const onRetrievedClick = (claimId) => () => {
        const dialogData = {
            title: '수거완료',
            message: '제품을 수거하셨습니까?',
            first: {
                onClick: () => { handleRetrieved(claimId); }
            },
            second: true,
        }
        showDialog(dialogData);
    }
    const handleRetrieved = (claimId) => {
        const data = { claimId };
        API.put('/claim/claim-retrieved', data)
        .then(() => {
            showSnackbar('반품완료 상태가 되었습니다.');
            handleRefresh();
        })
    }
    // end: 수거완료

    // 완료처리
    const onDoneClick = (claimId) => () => {
        const dialogData = {
            title: '완료처리',
            message: '강제로 완료처리되고 사용자는 추가적으로 알림은 받지 않습니다. 완료처리하시겠습니까?',
            first: {
                onClick: () => { handleDone(claimId); }
            },
            second: true,
        }
        showDialog(dialogData);
    }
    const handleDone = (claimId) => {
        const data = { claimId };
        API.put('/claim/make-claim-done', data)
        .then(() => {
            showSnackbar('반품완료 상태가 되었습니다.');
            handleRefresh();
        })
    }
    // end: 수거진행중

    // order info
    const [ orderModalOpen, setOrderModalOpen ] = useState(false);
    const [ orderId, setOrderId ] = useState();
    const handleOrderInfo = (orderId) => () => {
        setOrderId(orderId);
        setOrderModalOpen(true);
    }
    // end: order info

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>반품요청내역</PageTitle>
                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div>
                            <Typography style={{ color: colors.textSecondary }}>{'* 진행: 반품요청 -> 수거진행중 -> 수거완료'}</Typography>
                            <Typography style={{ color: colors.textSecondary }}>{'* 환불은 주문번호를 확인하시고 주문관리 페이지에서 해주세요.'}</Typography>
                        </div>
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <ReturnClaimTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage} 
                            onRejectClick={onRejectClick}
                            onRetrievingClick={onRetrievingClick}
                            onRetrievedClick={onRetrievedClick}
                            onDoneClick={onDoneClick}
                            handleOrderInfo={handleOrderInfo}
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>

            {/* reject modal */}
            <Dialog open={rejectModalOpen} onClose={() => { setRejectModalOpen(false); }}>
                <DialogTitle id="form-dialog-title">반품거절</DialogTitle>
                <DialogContent>
                    <DialogContentText>사용자는 알림을 받습니다. 코멘트를 간단히 입력하세요.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="코멘트"
                        fullWidth
                        value={reply}
                        onChange={event => { setReply(event.target.value); }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRejectModalOpen(false)} color="primary">취소</Button>
                    <Button onClick={handleReject} color="primary">확인</Button>
                </DialogActions>
            </Dialog>

            {/* order info */}
            <Dialog fullScreen open={orderModalOpen} onClose={() => { setOrderModalOpen(false); }}>
                <DialogTitle>주문정보</DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: '8px', display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button variant='contained' color="primary" onClick={() => { setOrderModalOpen(false); }}>닫기</Button>
                    </div>
                    {orderId && <OrderDetail orderId={orderId} />}
                </DialogContent>
            </Dialog>
        </div>
	);
}

export default ReturnClaimPage;