import React, { useState } from 'react';
import { useTheme, TextField, Paper, Button } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import CafeuserTableView from './CafeuserTableView';
import useCafeuserSearch from './useCafeuserSearch';


const CafeuserListPage = () => {
	const theme = useTheme();

    const [ page, setPage ] = useState(0);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ mobile, setMobile ] = useState('');
    const [ depositAvailablesMin, setDepositAvailablesMin ] = useState();
    const [ joinDateFrom, setJoinDateFrom ] = useState();
    const [ joinDateTo, setJoinDateTo ] = useState();
    const [ params, setParams ] = useState({});

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useCafeuserSearch(page, params);

    const handleInitClick = () => {
        setName('');
        setEmail('');
        setMobile('');
        setDepositAvailablesMin('');
        setJoinDateFrom('');
        setJoinDateTo('');
        setParams({});
        setPage(0);
    }

    const handleSearchClick = () => {
        setParams({
            name,
            email,
            mobile,
            joinDateFrom,
            joinDateTo,
            depositAvailablesMin,
        });
        setPage(0);
    }

	return (
		<div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>카페24 사용자</PageTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="닉네임"
                        size='small'
                        variant="outlined"
                        onChange={(event) => { setName(event.target.value) }}
                        value={name}
                    />
                    <TextField
                        label="이메일"
                        style={{ marginLeft: '8px' }}
                        type="email"
                        size='small'
                        variant="outlined"
                        onChange={(event) => { setEmail(event.target.value) }}
                        value={email}
                    />
                    <TextField
                        label="핸드폰번호 (숫자만)"
                        style={{ marginLeft: '8px' }}
                        type="text"
                        size='small'
                        variant="outlined"
                        onChange={(event) => { setMobile(event.target.value) }}
                        value={mobile}
                    />

                    <TextField
                        label="최소 사용가능한 적립금"
                        type="number"
                        style={{ marginLeft: '8px' }}
                        size='small'
                        variant="outlined"
                        onChange={(event) => { setDepositAvailablesMin(event.target.value) }}
                        value={depositAvailablesMin}
                    />

                    <TextField
                        label="회원가입 시작일"
                        type="date"
                        style={{ marginLeft: '16px' }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => { setJoinDateFrom(event.target.value) }}
                        value={joinDateFrom}
                    />

                    <TextField
                        label="회원가입 종료일"
                        type="date"
                        style={{ marginLeft: '16px' }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => { setJoinDateTo(event.target.value) }}
                        value={joinDateTo}
                    />

                    <Button style={{ marginLeft: 'auto'}} variant="contained" onClick={handleInitClick}>검색 초기화</Button>
                    <Button style={{ marginLeft: '8px'}} color="primary" variant="contained" onClick={handleSearchClick}>검색</Button>
                </div>

                {rows && <div style={{ marginTop: theme.spacing(4) }}>
                    <CafeuserTableView 
                        rows={rows} 
                        totalCount={totalCount} 
                        rowsInPage={rowsInPage} 
                        page={page} 
                        setPage={setPage} 
                    />
                </div>}
                {loading && <div>{loading}</div>}
                {error && <div>{error}</div>}
            </Paper>
		</div>
	);
}

export default CafeuserListPage;