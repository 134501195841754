import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Typography, makeStyles, useTheme, Button, TextField, Dialog, DialogTitle, DialogActions, ImageList, ImageListItem, ImageListItemBar, Checkbox, Paper, MenuItem, Switch, FormControlLabel } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import API from '../../../api';
import colors from '../../../appcolors';
import Divider from '../../../components/Divider';
import { AppContext } from '../../../contexts/app.context';
import { MemoryContext } from '../../../contexts/memory.context';
import useApi from '../../../hooks/useApi';
import Guide from 'components/Guide';
import CircularButton from 'components/CircularButton';


const ItemFormValidationSchema = yup.object({
    code: yup.string().required('필수입력입니다.'),
    category: yup.string().required('필수입력입니다.'),
    name: yup.string().required('필수입력입니다.'),
    content: yup.string().required('필수입력입니다.'),
    price: yup.number().required('필수입력입니다.'),
    priority: yup.number().required('필수입력입니다.'),
});

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        marginBottom: '8px',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
}));

export default function ItemFormPage() {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
    
    const { code } = useParams();

    const { showSnackbar, showDialog } = useContext(AppContext);
    const { products } = useContext(MemoryContext);

    // 제품정보
    const [ item, setItem ] = useState();
    useApi(() => {
        if (code) fetchItem();
        else setItem(null);
    }, [ code ]);
    const fetchItem = () => {
        API.get('/item/item/' + code)
        .then(setItem);
    }
    // end: 제품정보

    const handleAddItem = (values, { setSubmitting, resetForm }) => {
        if (!values.normalPrice) values.normalPrice = null;
        
        values.code = values.code.trim();
        values.name = values.name.trim();
        values.summary = values.summary.trim();

        API.post('/item/item', values)
        .then((item) => {
            showSnackbar('등록했습니다.');
            history.push('/item/form/' + item.code);
        })
        .finally(() => { setSubmitting(false) });
    };

    const handleModifyItem = (values, { setSubmitting, resetForm }) => {
        if (!values.normalPrice) values.normalPrice = null;
        API.put('/item/item', { 
            ...values,
            id: item.id,
            code: values.code.trim(),
        })
        .then(() => {
            showSnackbar('수정했습니다.');
        })
        .finally(() => { setSubmitting(false) });
    };

    const [ removeDialogOpen, setRemoveDialogOpen ] = useState(false);

    // 사진
    const [ imageURL, setImageURL ] = useState('');
    const handleImageUrlChange = (event) => {
        setImageURL(event.target.value);
    }
    const handleAddImageURL = () => {
        if (!imageURL) return showSnackbar('URL 을 입력하세요.');
        const fileInfo = {
            category: 'item',
            refId: item.id,
            downloadURL: imageURL,
            type: 'image',
        };
        API.post('/common/append-fileinfo', fileInfo, { timeout: 0 })
        .then(fileInfo => {
            setItem(item => {
                const result = {...item};
                result.fileInfos.push(fileInfo);
                return result;
            });
            setImageURL('');
        });
    }

    const [ files, setFiles ] = useState([]);
    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFiles(result => {
                return [
                    ...result,
                    ...e.target.files,
                ]
            });
        }
    }

    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const handleAddFileInfo = () => {
        if (files.length === 0) return;

        const promisis = files.map(file => {
            const data = new FormData();
            data.append('file', file);
            data.append('category', 'item');
            data.append('refId', item.id);
            data.append('withThumbnail', true);

            return API.post('/common/append-file', data);
        })

        setFileUploadLoading(true);
        Promise.all(promisis)
        .then(() => {
            fetchItem();
            setFiles([]);
        })
        .finally(() => { setFileUploadLoading(false); });
    }

    const handleModifyFileinfos = () => {
        const body = item.fileInfos.map(info => {
            return ({
                id: info.id,
                order: info.order,
            });
        });
        API.put('/common/fileinfos', body)
        .then(() => {
            fetchItem();
            showSnackbar('순서를 수정했습니다.');
        })
    }

    const handleRemoveFile = (fileInfo) => {
        API.delete('/common/remove-file/' + fileInfo.id)
        .then(() => {
            fetchItem();
            showSnackbar('파일을 삭제했습니다.');
        });
    }
    // end: 사진

    const handleMakeMain = (fileInfo) => {
        API.put('/common/make-file-main', { id: fileInfo.id })
        .then(() => {
            setItem(item => {
                const result = {...item};
                result.fileInfos.forEach(element => {
                    if (element.id === fileInfo.id) element.main = true
                    else element.main = false;
                });
                return result;
            });
            showSnackbar('대표사진으로 지정했습니다.');
        });
    }

    // 소속상품
    const [ productAssociations, setProductAssociations ] = useState();
    useEffect(() => {
        if (products && item) {
            const associations = products.map(product => {
                product.checked = !!item.products.find(el => el.id === product.id);
                return product;
            });
            setProductAssociations(associations);
        }
    }, [ products, item ])

    const handleProductCheck = (product, event) => {
        const data = {
            productId: product.id,
            itemId: item.id,
            exclude: !event.target.checked
        };
        API.post('/item/associate-item-with-product', data)
        .then(() => {
            const message = event.target.checked ? '상품에서 제외되었습니다.' : '상품에 포함되었습니다.';
            showSnackbar(message);
            fetchItem();
        })
    }
    // end: 소속상품

    // 복사
    const handleCopy = () => {
        showDialog({
            title: '제품 복사 생성',
            message: '제품을 복사 생성하시겠습니까??',
            first: {
                text: '확인',
                onClick: () => {
                    API.post('/item/copy', {
                        itemId: item.id
                    })
                    .then(() => {
                        showSnackbar('복사생성했습니다.');
                    });
                }
            },
        });
    }

    const handleRemove = () => {
        showDialog({
            title: '제품삭제',
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete('/item/item/' + item.id)
                    .then(() => {
                        showSnackbar('삭제했습니다.');
                        history.push('/item/list');
                    });
                }
            },
        });
    }

	return (
		<div className="pageMargin">
            <Paper className="pagePadding">
                <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '8px' }}>{code ? '제품정보' : '제품등록'}</Typography>
                <Divider />
                
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        code: item && item.code || '',
                        category: item && item.category || 'golf',
                        name: item && item.name || '',
                        summary: item && item.summary || '',
                        content: item && item.content || '',
                        price: item && item.price || '',
                        normalPrice: item && item.normalPrice || '',
                        spike: item && item.spike,
                        lace: item && item.lace,
                        gender: item && item.gender,
                        forCaddy: item && item.forCaddy || false,
                        priority: item && item.priority || '50',
                        expose: item && typeof(item.expose) === 'boolean' ? item.expose : true,
                        mgExpose: item && typeof(item.mgExpose) === 'boolean' ? item.mgExpose : false,
                    }}
                    validationSchema={ItemFormValidationSchema}
                    onSubmit={code ? handleModifyItem : handleAddItem}
                >
                    {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>제품번호 *</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        size='small'
                                        required={true}
                                        onChange={handleChange('code')}
                                        onBlur={handleBlur('code')}
                                        helperText={touched.code && errors.code || ' '}
                                        error={!!(touched.code && errors.code)}
                                        value={values.code}
                                    />
                                </div>
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>이름 *</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        size='small'
                                        required={true}
                                        onChange={handleChange('name')}
                                        onBlur={handleBlur('name')}
                                        helperText={touched.name && errors.name || ' '}
                                        error={!!(touched.name && errors.name)}
                                        value={values.name}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>요약 (MG 사이트에 노출)</Typography>
                                    <TextField
                                        multiline
                                        minRows={4}
                                        variant="outlined"
                                        className={classes.input}
                                        size='small'
                                        onChange={handleChange('summary')}
                                        onBlur={handleBlur('summary')}
                                        helperText={touched.summary && errors.summary || ' '}
                                        error={!!(touched.summary && errors.summary)}
                                        value={values.summary}
                                    />
                                </div>
                                
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>설명 *</Typography>
                                    <TextField
                                        multiline
                                        variant="outlined"
                                        className={classes.input}
                                        size='small'
                                        placeholder="HTML 형식"
                                        required={true}
                                        minRows={4}
                                        onChange={handleChange('content')}
                                        onBlur={handleBlur('content')}
                                        helperText={touched.content && errors.content || ' '}
                                        error={!!(touched.content && errors.content)}
                                        value={values.content}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>판매가격 (원) *</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        size='small'
                                        required={true}
                                        type="number"
                                        onChange={handleChange('price')}
                                        onBlur={handleBlur('price')}
                                        helperText={touched.price && errors.price || ' '}
                                        error={!!(touched.price && errors.price)}
                                        value={values.price}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>정상가 (원)</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        size='small'
                                        type="number"
                                        onChange={handleChange('normalPrice')}
                                        onBlur={handleBlur('normalPrice')}
                                        helperText={touched.normalPrice && errors.normalPrice || ' '}
                                        error={!!(touched.normalPrice && errors.normalPrice)}
                                        value={values.normalPrice}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>카테고리</Typography>
                                    <TextField
                                        select
                                        style={{ width: '100%' }}
                                        size='small'
                                        variant="outlined"
                                        label={'카테고리'}
                                        onChange={handleChange('category')}
                                        onBlur={handleBlur('category')}
                                        error={!!(touched.category && errors.category)}
                                        value={values.category || ''}
                                    >
                                        <MenuItem value={'golf'}>골프화</MenuItem>
                                        <MenuItem value={'height'}>키높이</MenuItem>
                                        <MenuItem value={'sneakers'}>운동화</MenuItem>
                                        <MenuItem value={'accessory'}>ACCESSORIES</MenuItem>
                                        <MenuItem value={'nischeom'}>NISCHEOM</MenuItem>
                                    </TextField>
                                </div>
                                    
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>스파이크 여부</Typography>
                                    <TextField
                                        select
                                        style={{ width: '100%' }}
                                        size='small'
                                        variant="outlined"
                                        label={'선택: 스파이크 / 스파이크리스'}
                                        onChange={handleChange('spike')}
                                        onBlur={handleBlur('spike')}
                                        error={!!(touched.spike && errors.spike)}
                                        value={values.spike || ''}
                                    >
                                        <MenuItem>선택안함</MenuItem>
                                        <MenuItem value={'spike'}>스파이크</MenuItem>
                                        <MenuItem value={'spikeless'}>스파이크리스</MenuItem>
                                    </TextField>
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>레이스 여부</Typography>
                                    <TextField
                                        select
                                        style={{ width: '100%' }}
                                        size='small'
                                        variant="outlined"
                                        label={'선택: 레이스 / 다이얼'}
                                        onChange={handleChange('lace')}
                                        onBlur={handleBlur('lace')}
                                        error={!!(touched.lace && errors.lace)}
                                        value={values.lace || ''}
                                    >
                                        <MenuItem>선택안함</MenuItem>
                                        <MenuItem value={'lace'}>레이스</MenuItem>
                                        <MenuItem value={'dial'}>다이얼</MenuItem>
                                    </TextField>
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>성별전용</Typography>
                                    <TextField
                                        select
                                        style={{ width: '100%' }}
                                        size='small'
                                        variant="outlined"
                                        label={'선택: 여성전용 / 남성전용'}
                                        onChange={handleChange('gender')}
                                        onBlur={handleBlur('gender')}
                                        error={!!(touched.gender && errors.gender)}
                                        value={values.gender || ''}
                                    >
                                        <MenuItem>선택안함</MenuItem>
                                        <MenuItem value={'female'}>여성전용</MenuItem>
                                        <MenuItem value={'male'}>남성전용</MenuItem>
                                    </TextField>
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>우선순위값 (1 ~ 1000), 높을수록 상단에 노출</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        size='small'
                                        type="number"
                                        onChange={handleChange('priority')}
                                        onBlur={handleBlur('priority')}
                                        helperText={touched.priority && errors.priority || ' '}
                                        error={!!(touched.priority && errors.priority)}
                                        value={values.priority}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <Typography variant="subtitle2" className={classes.label} style={{ marginBottom: 0, marginRight: '16px' }}>노출여부</Typography>
                                            <FormControlLabel
                                                control={<Switch checked={values.expose} onChange={handleChange('expose')} name="expose" />}
                                            />
                                        </div>
                                        <div>
                                            <Typography variant="subtitle2" className={classes.label} style={{ marginBottom: 0, marginRight: '16px' }}>MG 사이트 노출 여부</Typography>
                                            <FormControlLabel
                                                control={<Switch checked={values.mgExpose} onChange={handleChange('mgExpose')} name="mgExpose" />}
                                            />
                                        </div>
                                        <div style={{ marginLeft: '24px' }}>
                                            <Typography variant="subtitle2" className={classes.label} style={{ marginBottom: 0, marginRight: '16px' }}>캐디추천여부</Typography>
                                            <FormControlLabel
                                                control={<Switch checked={values.forCaddy} onChange={handleChange('forCaddy')} name="forCaddy" />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                    
                                {item && <>
                                    <div className={classes.section}>
                                        <Typography variant="subtitle2" className={classes.label}>사진</Typography>
                                        <ImageList rowHeight={160} cols={6} style={{ alignSelf: 'stretch' }}>
                                            {item.fileInfos.map((fileInfo, idx) => <ImageListItem key={fileInfo.id} cols={1}>
                                                <img src={fileInfo.downloadURL}></img>
                                                <ImageListItemBar
                                                    actionIcon={<>
                                                        {/* <Button type="button" size="small" color={fileInfo.main ? 'secondary' : 'default' } variant="contained" onClick={() => { handleMakeMain(fileInfo) }}>대표사진</Button> */}
                                                        <input type="number" placholder={'노출순서'} style={{ width: '40px' }} value={fileInfo.order || ''} onChange={(event) => {
                                                            setItem(prev => {
                                                                prev.fileInfos[idx].order = event.target.value;
                                                                return ({
                                                                    ...prev
                                                                });
                                                            });
                                                        }}></input>
                                                        <Button type="button" size="small" style={{ backgroundColor: 'red', color: 'white', marginLeft: '8px', marginRight: '8px' }} variant="contained" onClick={() => { handleRemoveFile(fileInfo) }}>삭제</Button>
                                                    </>}
                                                />
                                                
                                            </ImageListItem>)}
                                        </ImageList>
                                        <div style={{ marginTop: '8px', display: 'flex', alignItems: 'flex-end' }}>
                                            <Button variant={'outlined'} color="primary" onClick={handleModifyFileinfos}>순서 변경</Button>
                                            <Typography variant='body2' style={{ marginLeft: '8px', color: colors.textSecondary }}> {'순서를 변경하려면 클릭해주세요.'}</Typography>
                                        </div>
                                    </div>
                                    
                                    {/* file upload */}
                                    <div className={classes.section}>
                                        <div style={{ marginTop: '16px', border: '1px solid #dddddd', padding: '4px' }}>
                                            <input type="file" accept="image/*" onChange={handleFileChange} multiple />
                                            {files.length > 0 && <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '6px' }}>
                                                <CircularButton type="button" size="small" variant="outlined" loading={fileUploadLoading} onClick={handleAddFileInfo}>업로드</CircularButton>
                                                <Typography style={{ marginLeft: '4px', color: colors.textSecondary }} variant="caption">최대크기: 5MByte</Typography>
                                            </div>}
                                        </div>


                                        <div style={{ marginTop: '8px', display: 'flex', width: '100%' }}>
                                            <TextField
                                                variant="outlined"
                                                className={classes.input}
                                                style={{ flex: 1, marginRight: '8px' }}
                                                size="small"
                                                onChange={handleImageUrlChange}
                                            />
                                            <Button variant="outlined" size="small" color="secondary" onClick={handleAddImageURL}>이미지 URL 추가</Button>
                                        </div>
                                    </div>

                                    {productAssociations && <div className={classes.section}>
                                        <div style={{ marginTop: '24px' }}>
                                            <Typography variant="subtitle2" className={classes.label}>소속상품</Typography>
                                            {productAssociations.map(item => <Fragment key={item.id}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1">{item.name}</Typography>
                                                    <Checkbox checked={item.checked} onChange={(event) => handleProductCheck(item, event)} />
                                                </div>
                                                
                                            </Fragment>)}
                                        </div>
                                    </div>}
                                </>}

                                <div style={{ marginTop: '24px' }}>
                                    <Button type="button" style={{ marginRight: '8px' }} variant="contained" color="secondary" onClick={() => { history.push('/item/list') }}>목록</Button>
                                    {code && <>
                                        <Button type="button" style={{ marginRight: '8px', backgroundColor: 'red', color: 'white' }} variant="contained" onClick={handleRemove}>삭제</Button>
                                        <Button type="button" style={{ marginRight: '8px', backgroundColor: colors.primaryDark, color: 'white' }} variant="contained" onClick={() => { history.push('/item/' + item.id + '/options') }}>옵션관리</Button>
                                        <Button type="button" style={{ marginRight: '8px', backgroundColor: colors.primaryDark, color: 'white' }} variant="contained" onClick={() => { history.push('/item/' + item.code + '/option-images') }}>옵션이미지관리</Button>
                                        <Button type="button" style={{ marginRight: '8px', backgroundColor: colors.primaryDark, color: 'white' }} variant="contained" onClick={() => { history.push('/item/' + item.id + '/notation') }}>필수표기정보</Button>
                                        <Button type="button" style={{ marginRight: '8px', backgroundColor: colors.primaryDark, color: 'white' }} variant="contained" onClick={() => { history.push('/item/' + item.id + '/link') }}>관련링크</Button>
                                        <Button type="button" style={{ marginRight: '8px', backgroundColor: colors.primaryDark, color: 'white' }} variant="contained" onClick={handleCopy}>복사생성하기</Button>
                                        <Button type="button" component={'a'} target='_blank' style={{ marginRight: '8px', backgroundColor: colors.primaryLight, color: 'white' }} variant="contained" href={`${process.env.REACT_APP_HOST}/item/${code}`}>사이트보기</Button>
                                        
                                    </>}
                                    <Button type="submit" variant="contained" color="primary">{code ? '수정' : '등록'}</Button>
                                </div>
                                
                                {!code && <div style={{ marginTop: '16px'}}>
                                    <Guide>상품의 이미지 또는 속성은 먼저 등록후 편집 가능합니다.</Guide>
                                </div>}
                            </form>
                        

                            {values.content && <Paper className="pagePadding" style={{ marginTop: '24px' }}>
                                <Typography variant="subtitle2" className={classes.label}>설명 미리보기</Typography>
                                <div style={{ textAlign: 'center' }}>
                                    <div dangerouslySetInnerHTML={{ __html: values.content }} />
                                </div>
                            </Paper>}
                        </>
                    )}
                </Formik>
            </Paper>
            

            <Dialog onClose={() => setRemoveDialogOpen(false)} open={removeDialogOpen}>
                <DialogTitle>{'정말 삭제하시겠습니까?'}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleRemove} style={{ color: 'red' }}>예</Button>
                    <Button onClick={() => { setRemoveDialogOpen(false)}} color="primary" autoFocus>아니오</Button>
                </DialogActions>
            </Dialog>
        </div>
	);
}