import React, { useEffect, useMemo } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from 'recharts';



const VisitCountChart = ({ dataset }) => {

	return (
		<BarChart width={1600} height={400} data={dataset}>
            <Bar type="monotone" dataKey="count" fill="#8884d8" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="targetDate" />
            <YAxis />
        </BarChart>
	);
}

export default VisitCountChart;