import React from 'react';
import { makeStyles, TableFooter, TablePagination, Button, } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { datetimePipe, itemCategoryPipe } from '../../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
    },
}));

export default function ItemTableView({ rows, totalCount, rowsInPage, page, setPage, intend, onSelected }) {
	const classes = useStyles();
    const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


	return (
		<>
			<TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{'일련번호'}</StyledTableCell>
                            <StyledTableCell align="left">{'코드'}</StyledTableCell>
                            {intend === 'list' && <StyledTableCell align="left">{'이름'}</StyledTableCell>}
                            {intend === 'list' && <StyledTableCell align="left">{'카테고리'}</StyledTableCell>}
                            {intend === 'list' && <StyledTableCell align="left">{'등록일'}</StyledTableCell>}
                            <StyledTableCell align="center">{'관리'}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                                <StyledTableCell align="left">{row.code}</StyledTableCell>
                                {intend === 'list' && <StyledTableCell align="left">{row.name}</StyledTableCell>}
                                {intend === 'list' && <StyledTableCell align="left">{itemCategoryPipe(row.category)}</StyledTableCell>}
                                {intend === 'list' && <StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>}
                                <StyledTableCell align="center">
                                    {intend === 'list' && <>
                                        <Button variant="outlined" size="small" component={RouterLink} to={`/item/form/${row.code}`}>수정</Button>
                                        <Button variant="outlined" size="small" component={RouterLink} to={`/item/detail/${row.code}`} style={{ marginLeft: '4px' }}>보기</Button>
                                    </>}
                                    {intend === 'select' && <>
                                        <Button variant="outlined" size="small" onClick={() => { onSelected(row); }}>선택</Button>
                                    </>}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            {rowsInPage && <TablePagination
                                rowsPerPageOptions={[rowsInPage]}
                                colSpan={6}
                                count={+totalCount}
                                rowsPerPage={rowsInPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
		</>
	);
}
