import React from 'react';
import { makeStyles, TableFooter, TablePagination, } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';
import { datetimePipe } from 'pipes';



const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
    },
}));

export default function SmsTable({ rows, totalCount, rowsInPage, page, setPage }) {
	const classes = useStyles();

    const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

	return (
		<>
			<TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{'일련번호'}</StyledTableCell>
                            <StyledTableCell>{'보낸일시'}</StyledTableCell>
                            <StyledTableCell>{'로그'}</StyledTableCell>
                            <StyledTableCell>{'수신번호'}</StyledTableCell>
                            <StyledTableCell>{'메시지'}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                                <StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>
                                <StyledTableCell align="left">{row.message}</StyledTableCell>
                                <StyledTableCell align="left">{row.meta.receivers?.join(',')}</StyledTableCell>
                                <StyledTableCell align="left">{row.meta.message}</StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={5} />
                            </TableRow>
                        )}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            {rowsInPage && <TablePagination
                                rowsPerPageOptions={[rowsInPage]}
                                colSpan={9}
                                count={+totalCount}
                                rowsPerPage={rowsInPage}
                                page={page}
                                SelectProps={{
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
		</>
	);
}