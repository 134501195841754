import React, { useContext, useEffect, useMemo } from 'react';
import { Typography, makeStyles, Button, TextField, Paper, Box, Checkbox } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PageTitle from 'components/PageTitle';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import StuffGenerator from './StuffGenerator';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const ValidationSchema = yup.object({
    userId: yup.number().nullable(),
    zipcode: yup.string().required(),
    address: yup.string().required(),
    addressDetail: yup.string().required(),
    name: yup.string().required(),
    item: yup.object().required(),
    proxy: yup.object().nullable(),
    count: yup.number().required().min(1).max(100),
});

const useStyles = makeStyles((theme) => ({

}));

export default function GenerateBucketPage() {
	const classes = useStyles();

    const { showSnackbar } = useContext(AppContext);

    const { search } = useLocation();
    const claimId = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('claimId');
    }, [search]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: '',
            zipcode: '',
            address: '',
            addressDetail: '',
            name: '',
            item: null,
            proxy: null,
            count: '',
            dev: false,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (values.userId == '') values.userId = null;
            const userId = values.userId == '' ? null : values.userId;
            const destination = {
                zipcode: values.zipcode,
                address: values.address,
                addressDetail: values.addressDetail,
                name: values.name,
            };
            const stuff = {
                userId,
                itemId: values.item.id,
                proxyId: values.proxy?.id,
                count: values.count,
                price: values.proxy?.price || values.item.price,
            };
            const data = {
                userId,
                destination,
                stuff,
                claimId,
                dev: values.dev,
            };

            API.post('/bucket/create-bucket', data)
            .then((item) => {
                showSnackbar('생성했습니다.');
                resetForm();
            })
            .finally(() => { setSubmitting(false) });
        },
    });

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    // ----- claim -----
    useEffect(() => {
        if (claimId) {
            API.get('/claim/claim/' + claimId)
            .then(claim => {
                setFieldValue('userId', claim.userId);
                setFieldValue('zipcode', claim.bucket?.destination?.zipcode);
                setFieldValue('address', claim.bucket?.destination?.address);
                setFieldValue('addressDetail', claim.bucket?.destination?.addressDetail);
                setFieldValue('name', claim.bucket?.destination?.name);
                setFieldValue('mobile', claim.bucket?.destination?.mobile);
            })
        }
    }, [ claimId ]);
    // ----- end: claim -----

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>버킷생성</PageTitle>

                <form onSubmit={handleSubmit}>
                    <Box display="flex">
                        <Box flex={1}>
                            <Typography variant="subtitle2" className={classes.label}>UserId</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                size="small"
                                onChange={handleChange('userId')}
                                onBlur={handleBlur('userId')}
                                helperText={(touched.userId && errors.userId) || '없으면 비워두세요.'}
                                error={!!(touched.userId && errors.userId)}
                                value={values.userId}
                            />

                            <Typography variant="subtitle2" className={classes.label}>배송지 우편번호 *</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                required={true}
                                size="small"
                                onChange={handleChange('zipcode')}
                                onBlur={handleBlur('zipcode')}
                                helperText={(touched.zipcode && errors.zipcode) || ' '}
                                error={!!(touched.zipcode && errors.zipcode)}
                                value={values.zipcode}
                            />

                            <Typography variant="subtitle2" className={classes.label}>배송지 주소 *</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                required={true}
                                size="small"
                                onChange={handleChange('address')}
                                onBlur={handleBlur('address')}
                                helperText={(touched.address && errors.address) || ' '}
                                error={!!(touched.address && errors.address)}
                                value={values.address}
                            />

                            <Typography variant="subtitle2" className={classes.label}>배송지 상세주소 *</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                required={true}
                                size="small"
                                onChange={handleChange('addressDetail')}
                                onBlur={handleBlur('addressDetail')}
                                helperText={(touched.addressDetail && errors.addressDetail) || ' '}
                                error={!!(touched.addressDetail && errors.addressDetail)}
                                value={values.addressDetail}
                            />

                            <Typography variant="subtitle2" className={classes.label}>받는사람성함 *</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                required={true}
                                size="small"
                                onChange={handleChange('name')}
                                onBlur={handleBlur('name')}
                                helperText={(touched.name && errors.name) || ' '}
                                error={!!(touched.name && errors.name)}
                                value={values.name}
                            />

                            <Box display={'flex'} alignItems={'center'}>
                                <Checkbox checked={values.dev} onChange={handleChange('dev')} />
                                <Typography>테스트목적</Typography>
                            </Box>
                            
                        </Box>
                        <Box flex={2}>
                            <StuffGenerator formik={formik} />
                        </Box>
                    </Box>

                    {/* {JSON.stringify(values)} */}
                    
                    <div style={{ marginTop: '16px' }}>
                        <Button type="submit" variant="contained" color="primary">생성</Button>
                    </div>
                </form>
            </Paper>
        </div>
	);
}