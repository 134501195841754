import React from 'react';
import { makeStyles, useTheme, TableFooter, TablePagination, } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { genderPipe, datetimePipe, authsPipe, user_state_pipe } from '../../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';



const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
    },
}));

export default function UserTableView({ rows, totalCount, rowsInPage, page, setPage }) {
	const classes = useStyles();
	const theme = useTheme();

    const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

	return (
		<>
			<TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{'일련번호'}</StyledTableCell>
                            <StyledTableCell align="left">{'닉네임'}</StyledTableCell>
                            <StyledTableCell align="left">{'이메일'}</StyledTableCell>
                            <StyledTableCell align="left">{'카카오계정'}</StyledTableCell>
                            <StyledTableCell align="left">{'권한'}</StyledTableCell>
                            <StyledTableCell align="left">{'성별'}</StyledTableCell>
                            <StyledTableCell align="right">{'신발사이즈'}</StyledTableCell>
                            <StyledTableCell align="left">{'상태'}</StyledTableCell>
                            <StyledTableCell align="left">{'가입일시'}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                                <StyledTableCell align="left">{row.nickname}</StyledTableCell>
                                <StyledTableCell align="left">{row.email}</StyledTableCell>
                                <StyledTableCell align="left">{row.kakaoId ? '연결됨' : '-'}</StyledTableCell>
                                <StyledTableCell align="left">{authsPipe(row.auths)}</StyledTableCell>
                                <StyledTableCell align="left">{genderPipe(row.sex)}</StyledTableCell>
                                <StyledTableCell align="right">{row.shoesSize}</StyledTableCell>
                                <StyledTableCell align="left">{user_state_pipe(row.state)}</StyledTableCell>
                                <StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={9} />
                            </TableRow>
                        )}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            {rowsInPage && <TablePagination
                                rowsPerPageOptions={[rowsInPage]}
                                colSpan={9}
                                count={+totalCount}
                                rowsPerPage={rowsInPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
		</>
	);
}
