import React, { useState, useContext } from 'react';
import { Box, makeStyles, useTheme, Button, TextField, Paper, IconButton, Collapse } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import useApi from 'hooks/useApi';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import Proxies from '../proxy/Proxies';
import PageTitle from 'components/PageTitle';


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const OptionRow = ({ option, setTrigger }) => {
    const { showDialog, showSnackbar } = useContext(AppContext);

    const handleRemoveOption = (option) => {
        showDialog({
            title: '옵션 삭제',
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete('/option/option/' + option.id)
                    .then(() => {
                        setTrigger(new Date().getTime());
                        showSnackbar('삭제했습니다.');
                    });
                }
            },
        });
    }

    const [ optionName, setOptionName ] = useState(option.name);
    const handleModify = () => {
        const data = {
            id: option.id,
            name: optionName,
        }

        API.put('/option/option', data)
        .then(() => {
            showSnackbar('수정했습니다.');
        })
    }

    return (
        <TableRow key={option.id}>
            <TableCell component="th" scope="row">{option.id}</TableCell>
            <TableCell>
                <input type="text" value={optionName} onChange={event => setOptionName(event.target.value)} />
            </TableCell>
            <TableCell align="right">{option.order}</TableCell>
            <TableCell align="center">
                <Button  variant="outlined" size="small" color="secondary" onClick={() => { handleRemoveOption(option) }}>삭제</Button>
                <Button style={{ marginLeft: '4px' }} variant="outlined" size="small" onClick={handleModify}>수정</Button>
            </TableCell>
        </TableRow>
    );
}


function Row(props) {
    const { row, setTrigger } = props;
    const [ open, setOpen ] = useState(false);
    const classes = useRowStyles();
    const history = useHistory();

    const { showDialog, showSnackbar } = useContext(AppContext);

    const handleRemoveSelect = () => {
        showDialog({
            title: '셀렉션 삭제',
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete('/option/select/' + row.id)
                    .then(() => {
                        setTrigger(new Date().getTime());
                        showSnackbar('삭제했습니다.');
                    });
                }
            },
        });
    }

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell align="left" component="th" scope="row">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.order}</TableCell>
                <TableCell align="left">{row.options.length}</TableCell>
                <TableCell align="center">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <Button variant="outlined" size="small" color="secondary" onClick={handleRemoveSelect}>삭제</Button>
                    <Button variant="outlined" size="small" color="primary" style={{ marginLeft: '4px' }} onClick={() => { history.push('/option/option-register/' + row.id) }}>옵션추가</Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>일련번호</TableCell>
                                        <TableCell>이름</TableCell>
                                        <TableCell align="right">순서</TableCell>
                                        <TableCell align="center">관리</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.options.map((option) => <OptionRow key={option.id} option={option} setTrigger={setTrigger} />)}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}




const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));


export default function OptionListPage() {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();

    const { showAlert, showSnackbar } = useContext(AppContext);

    const { itemId } = useParams();
    
    const [ selects, setSelects ] = useState();

    const [ trigger, setTrigger ] = useState();

    useApi(() => {
        loadData();
    }, [ trigger ]);

    const loadData = () => {
        API.get('/option/selects/' + itemId)
        .then(setSelects);
    }

    const [ copyDialogOpen, setCopyDialogOpen ] = useState(false);
    const [ copySelectId, setCopySelectId ] = useState();
    const handleCopySelect = () => {
        if (!copySelectId) return showAlert('셀력션의 일련번호를 입력하세요.');
        const data = {
            itemId, 
            selectId: copySelectId,
        };
        API.post('/option/copy-select', data)
        .then(() => {
            showSnackbar('셀력션을 복사했습니다.');
            loadData();
        });
    }

	return (
        <>
            <div className="pageMargin">
                <Paper className="pagePadding">
                    <PageTitle>옵션관리</PageTitle>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end', marginBottom: '8px' }}>
                        <Button variant="contained" color="secondary" component={RouterLink} to={'/option/select-register/' + itemId}>추가</Button>
                        
                        <Button variant="contained" style={{ marginRight: '4px'}} onClick={() => { setCopyDialogOpen(true) }}>옵션 일괄 복사</Button>
                    </div>
                    
                    {selects && <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">일련번호</TableCell>
                                    <TableCell align="left">이름</TableCell>
                                    <TableCell align="left">순서</TableCell>
                                    <TableCell align="left">옵션수</TableCell>
                                    <TableCell align="left">옵션</TableCell>
                                    <TableCell align="center">관리</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selects.map((row) => <Row key={row.id} row={row} setTrigger={setTrigger} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Paper>

                {<Dialog
                    open={copyDialogOpen}
                    onClose={() => { setCopyDialogOpen(false); }}
                >
                    <DialogTitle>복사</DialogTitle>
                    <DialogContent style={{ minWidth: '240px' }}>
                        <DialogContentText>복사하려는 셀력션의 일련번호를 입력하세요.</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="text"
                            fullWidth
                            onChange={event => { setCopySelectId(event.target.value) }}
                            value={copySelectId}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={() => {
                                setCopyDialogOpen(false);
                            }} 
                            color="secondary"
                        >
                            {'취소'}
                        </Button>
                        <Button 
                            onClick={() => { 
                                handleCopySelect()
                                setCopyDialogOpen(false); 
                            }} 
                            color="primary" 
                            autoFocus
                        >
                            {'확인'}
                        </Button>
                    </DialogActions>
                </Dialog>}
            </div>
            <Proxies trigger={trigger} />

            <div style={{ marginLeft: '24px' }}>
                {/* 뒤로가기 보다는 목록을 가는게 더 좋지만 그러려면 id 말고 코드를 알아야 하는데.. 번거로움 */}
                <Button variant="contained" color="primary" onClick={() => history.goBack()}>뒤로</Button>
            </div>
        </>
	);
}
