import React, { useState, useEffect, useContext } from 'react';
import { Typography, makeStyles, Button, TextField, Paper, Divider, TableContainer, Table, TableHead, TableRow, TableBody, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import Loading from 'components/Loading';
import colors from 'appcolors';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import StyledTableCell from 'components/StyledTableCell';
import StyledTableRow from 'components/StyledTableRow';
import { platform_pipe } from 'pipes';


const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        marginBottom: '8px',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
}));

const ItemLinkPage = () => {
	const classes = useStyles();
	const history = useHistory();

    const { itemId } = useParams();

    const { showSnackbar } = useContext(AppContext);

    // load data
    const [ loading, setLoading ] = useState(true);
    const [ links, setLinks ] = useState();
	const [ trigger, setTrigger ] = useState();
    useEffect(() => {
        setLoading(true);
        API.get(`/link/links?code=item&itemId=${itemId}`)
        .then(setLinks)
        .finally(() => { setLoading(false); });
    }, [ itemId, trigger ]);
    // end: load data

	const handleRemove = (link) => () => {
		API.delete(`/link/${link.id}`)
		.then(() => {
			showSnackbar('삭제했습니다.');
			setTrigger(new Date().getTime());
		})
	}

	// form modal
	const [ modalOpen, setModalOpen ] = useState(false);
	// end: form modal

    if (loading) return (<Loading />);

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '8px' }}>제품관련링크</Typography>
                <Divider />
                <Typography variant="h6" style={{ color: colors.secondary }}>*제품 상세페이지와 관련된 링크를 등록해주세요. 사용자들은 mnx15.vision 상세페이지에서 링크를 볼 수 있습니다.</Typography>

				<div style={{ marginTop: '16px', textAlign: 'right' }}>
					<Button type="button" variant='contained' color="primary" onClick={() => { setModalOpen(true); }}>추가</Button>
				</div>

				<TableContainer component={Paper} style={{ marginTop: '8px' }}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<StyledTableCell align="left">{'플랫폼'}</StyledTableCell>
								<StyledTableCell align="left">{'링크 URL'}</StyledTableCell>
								<StyledTableCell align="left">{'관리'}</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{links.map((row) => (
								<StyledTableRow key={row.id}>
									<StyledTableCell align="left">{platform_pipe(row.platform)}</StyledTableCell>
									<StyledTableCell align="left">{row.url}</StyledTableCell>
									<StyledTableCell align="left">
										<Button variant='contained' size='small' style={{ backgroundColor: colors.error, color: 'white' }} onClick={handleRemove(row)}>삭제</Button>
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>


                
            </Paper>

			{/* form modal */}
			<Formik
				initialValues={{
					platform: 'coupang',
					url: '',
				}}
				validationSchema={yup.object({
					platform: yup.string().required(),
					url: yup.string().url().required(),
				})}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					const data = {
						...values,
						code: 'item', 
						itemId, 
					}
					API.post('/link', data)
					.then(() => {
						showSnackbar('추가했습니다.');
						resetForm();
						setSubmitting(false);
						setModalOpen(false);
						setTrigger(new Date().getTime());
					})
				}}
			>
				{({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
					<>
						<Dialog maxWidth="sm" fullWidth={true} open={modalOpen} onClose={() => { setModalOpen(false); }}>
							<form onSubmit={handleSubmit}>
								<DialogTitle>링크 추가</DialogTitle>
								<DialogContent>
									<div className={classes.section}>
										<Typography variant="subtitle2" className={classes.label}>플랫폼</Typography>
										<TextField
											select
											size="small"
											variant="outlined"
											className={classes.input}
											onChange={handleChange('platform')}
											onBlur={handleBlur('platform')}
											value={values.platform}
										>
											<MenuItem value={'coupang'}>쿠팡</MenuItem>
											<MenuItem value={'naver'}>네이버</MenuItem>
										</TextField>
									</div>

									<div className={classes.section}>
										<Typography variant="subtitle2" className={classes.label}>URL</Typography>
										<TextField
											variant="outlined"
											size="small"
											className={classes.input}
											onChange={handleChange('url')}
											onBlur={handleBlur('url')}
											value={values.url}
										/>
									</div>
								</DialogContent>
								<DialogActions>
									<Button type="button" variant="contained" color="secondary" onClick={() => setModalOpen(false)}>취소</Button>
									<Button type="submit" style={{ marginLeft: '8px' }} variant="contained" color="primary">추가</Button>
								</DialogActions>
							</form>
						</Dialog>
					</>
				)}
			</Formik>
        </div>
	);
}

export default ItemLinkPage;