import React, { useContext } from 'react';
import { Typography, makeStyles, Button, TextField, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import PageTitle from 'components/PageTitle';
import colors from 'appcolors';



const useStyles = makeStyles((theme) => ({

}));

const AlarmPage = () => {
	const classes = useStyles();
	const history = useHistory();
    
    const { showSnackbar } = useContext(AppContext);

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            userId: '',
            message: '',
        },
        validationSchema: yup.object({
            userId: yup.number().required(),
            message: yup.string().required(),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            API.post('/alarm/alarm', values)
            .then(() => {
                showSnackbar('알림을 보냈습니다.');
                resetForm();
            })
            .finally(() => { setSubmitting(false) });
        },
    });

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>알림 보내기</PageTitle>
                <Typography variant='body1' style={{ marginBottom: '32px', color: colors.textSecondary }}>사용자의 이메일로 알림 메시지를 전송합니다.</Typography>

                <form onSubmit={handleSubmit}>
                    <Typography variant="subtitle2" className={classes.label}>사용자 아이디 (숫자)</Typography>
                    <TextField
                        variant="outlined"
                        className={classes.input}
                        required={true}
                        size="small"
                        onChange={handleChange('userId')}
                        onBlur={handleBlur('userId')}
                        helperText={(touched.userId && errors.userId) || ' '}
                        error={!!(touched.userId && errors.userId)}
                        value={values.userId}
                    />

                    <Typography variant="subtitle2" className={classes.label}>메세지</Typography>
                    <TextField
                        variant="outlined"
                        className={classes.input}
                        required={true}
                        multiline
                        rows={4}
                        size="small"
                        style={{ width: '100%' }}
                        onChange={handleChange('message')}
                        onBlur={handleBlur('message')}
                        helperText={(touched.message && errors.message) || ' '}
                        error={!!(touched.message && errors.message)}
                        value={values.message}
                    />

                    <div style={{ marginTop: '16px' }}>
                        <Button type="submit" variant="contained" color="primary">보내기</Button>
                    </div>
                </form>
            </Paper>
        </div>
	);
}

export default AlarmPage;