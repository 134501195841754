import React, { useState, useEffect, useContext } from 'react';
import { Typography, makeStyles, useTheme, Paper, Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Loading from '../../components/Loading';
import { AuthContext } from '../../contexts/auth.context';
import API from '../../api';
import Ticket from './Ticket';
import PageTitle from 'components/PageTitle';
import Guide from 'components/Guide';



const useStyles = makeStyles((theme) => ({

}));

export default function TicketPage() {
    const { me } = useContext(AuthContext);

    // data
    const [ data, setData ] = useState();

    // parameters
    const [ page, setPage ] = useState(1);
    const [ state, setState ] = useState('opened');

    // interface parameters
    const [ loading, setLoading ] = useState(false);
    const [ trigger, setTrigger ] = useState();
 
    useEffect(() => {
        if (me) {
           initLoad(); 
        }
    }, [ me, state ]);

    // 파라미터 (state) 가 바뀌면 초기화
    const initLoad = () => {
        setData(null);
        setPage(1);
        setTrigger(new Date().getTime());
    }

    useEffect(() => {
        if (trigger) {
            setTrigger(null);
            setLoading(true);

            const params = { page };
            if (state) params['state'] = state;
            API.get('/ticket/tickets', { params })
            .then(setData)
            .finally(() => {
                setLoading(false);
            });
        }
    }, [ trigger ]);

    const [ categories, setCategories ] = useState();
    useEffect(() => {
        API.get('/closed/inquiry-categories')
        .then(setCategories);
    }, []);

    if (!categories) return null;

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>1:1문의</PageTitle>

                <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center', flexFlow: 'row-reverse' }}>
                    <Checkbox
                        checked={state === 'opened'}
                        onChange={event => { 
                            if (event.target.checked) {
                                setState('opened');
                            }
                            else {
                                setState(null);
                            }
                        }}
                    />
                    <Typography variant="body1">진행중인 문의만 보기</Typography>
                </div>

                {!loading ? <>
                    {data && <>
                        {data.rows.length > 0 ? <>
                            {data.rows.map(item => <Ticket key={item.id} ticket={item} loadData={initLoad} categories={categories} />)}
                        </> : <Guide>데이터가 없습니다.</Guide>}
                    </>}
                </> : <>
                    <Loading />
                </>}
                
                {data && <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
                    <Pagination count={data.totalPage} color="primary" page={data.page} onChange={(event, page) => {
                        setPage(page);
                        setTrigger(new Date().getTime());
                    }} />
                </div>}
            </Paper>
        </div>
	);
}
