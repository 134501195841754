import React, { useState } from 'react';
import { Button, MenuItem, Paper, TextField, useTheme } from '@material-ui/core';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import useSearchCoupon from 'api/coupon/useSearchCoupon';
import CouponTable from './CouponTable';


const CouponListPage = () => {
    const theme = useTheme();

    const [ code, setCode ] = useState('');
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();
    
    const { loading, error, rows, mayMore, totalCount, rowsInPage } = useSearchCoupon(code, page, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>쿠폰내역</PageTitle>

                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            select
                            style={{ minWidth: '120px' }}
                            size='small'
                            variant="outlined"
                            label={'구분'}
                            onChange={(event) => { setCode(event.target.value); }}
                            value={code}
                        >
                            <MenuItem value=''>선택안함</MenuItem>
                            <MenuItem value={'manager_generate'}>매니저 발급</MenuItem>
                            <MenuItem value={'day15'}>MNX15 데이</MenuItem>
                        </TextField>

                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <CouponTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage} 
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>
        </div>
	);
}

export default CouponListPage;