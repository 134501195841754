import React, { useState, useContext } from 'react';
import { useTheme, Button, TextField, MenuItem } from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useItemSearch from './useItemSearch';
import ItemTableView from './ItemTableView';



const ItemSearch = ({ intend, onSelected }) => {
	const theme = useTheme();

    const [ category, setCategory ] = useState();
    const [ keyword, setKeyword ] = useState('');
    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
        setPage(0);
    }
    
    const [ page, setPage ] = useState(0);
    const [ orderby, setOrderby ] = useState('recent');

    const { 
        rows,
        loading,
        totalCount,
        rowsInPage,
    } = useItemSearch(keyword, category, page, setPage, orderby);

	return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    select
                    size='small'
                    style={{ marginRight: '8px', minWidth: '180px' }}
                    variant="outlined"
                    label={'카테고리'}
                    value={category}
                    onChange={event => { setCategory(event.target.value); }}
                >
                    <MenuItem>전체</MenuItem>
                    <MenuItem value={'golf'}>골프</MenuItem>
                    <MenuItem value={'height'}>키높이</MenuItem>
                    <MenuItem value={'sneakers'}>운동화</MenuItem>
                </TextField>

                <TextField
                    label="키워드"
                    variant="outlined"
                    size="small"
                    required={false}
                    onChange={handleKeywordChange}
                    value={keyword}
                />

                <TextField
                    select
                    style={{ marginLeft: '8px' }}
                    size='small'
                    variant="outlined"
                    label={'정렬'}
                    onChange={event => { setOrderby(event.target.value); }}
                    value={orderby}
                >
                    <MenuItem value={'recent'}>최신순</MenuItem>
                    <MenuItem value={'priority'}>중요도순</MenuItem>
                </TextField>

                {intend === 'list' && <Button style={{ marginLeft: 'auto', alignSelf: 'flex-end' }} size="large" color="secondary" variant="contained" component={RouteLink} to="/item/register">제품등록</Button>}
            </div>

            {!loading ? <div style={{ marginTop: theme.spacing(4) }}>
                <ItemTableView
                    loading={loading}
                    rows={rows} 
                    totalCount={totalCount}
                    rowsInPage={rowsInPage} 
                    page={page} 
                    setPage={setPage}
                    intend={intend}
                    onSelected={onSelected}
                />
            </div> : <Loading />}
        </>
	);
}

export default ItemSearch;