import React, { useState } from 'react';
import { Typography, useTheme, TextField, Slider, Checkbox, Paper, Button } from '@material-ui/core';
import useUserSearch from './useUserSearch';
import UserTableView from './UserTableView';
import PageTitle from 'components/PageTitle';


const sizeMarks = [
    { value: 200, label: '200' },
    { value: 220, label: '220' },
    { value: 240, label: '240' },
    { value: 260, label: '260' },
    { value: 280, label: '280' },
    { value: 300, label: '300' },
];

const UserListPage = () => {
	const theme = useTheme();

    const [ page, setPage ] = useState(0);
    const [ params, setParams ] = useState({});
    
    const [ keyword, setKeyword ] = useState('');
    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
        setPage(0);
    }

    const [ sizeCheck, setSizeCheck ] = useState(false);
    const handleSizeCheckChange = () => {
        setSizeCheck(val => !val);
    }

    const [ sizeRange, setSizeRange ] = useState([200, 300]);
    const handleSizeRangeChange = (e, value) => {
        setSizeRange(value);
        setSizeCheck(true);
        setPage(0);
    }

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useUserSearch(page, params);

    const handleInitClick = () => {
        setKeyword('');
        setSizeCheck(false);
        setSizeRange([200, 300]);
        setParams({});
        setPage(0);
    }

    const handleSearchClick = () => {
        setParams({
            keyword,
            sizeCheck,
            sizeRange,
        });
        setPage(0);
    }

	return (
		<div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>사용자</PageTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="키워드 (닉네임, 이메일)"
                        size="small"
                        variant="outlined"
                        required={false}
                        onChange={handleKeywordChange}
                        value={keyword}
                    />
                    <div style={{ flex: 1, maxWidth: 300, marginLeft: '40px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography id="range-slider" gutterBottom>신발 사이즈 (mm)</Typography>
                            <Checkbox checked={sizeCheck} onChange={handleSizeCheckChange} name="sizeCheck" />
                        </div>
                        <Slider
                            min={200}
                            max={300}
                            step={5}
                            marks={sizeMarks}
                            value={sizeRange}
                            onChange={handleSizeRangeChange}
                            valueLabelDisplay="auto"
                        />
                    </div>

                    <Button style={{ marginLeft: 'auto'}} variant="contained" onClick={handleInitClick}>검색 초기화</Button>
                    <Button style={{ marginLeft: '8px'}} color="primary" variant="contained" onClick={handleSearchClick}>검색</Button>
                </div>

                {rows && <div style={{ marginTop: theme.spacing(4) }}>
                    <UserTableView 
                        rows={rows} 
                        totalCount={totalCount} 
                        rowsInPage={rowsInPage} 
                        page={page} 
                        setPage={setPage} 
                    />
                </div>}
                {loading && <div>{loading}</div>}
                {error && <div>{error}</div>}
            </Paper>
		</div>
	);
}

export default UserListPage;