import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import { Container, Box, Grid, Typography, makeStyles, Button, Link, TextField, Paper, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import PageTitle from 'components/PageTitle';
import colors from 'appcolors';
import { AppContext } from 'contexts/app.context';
import useGetAppLogs from './useGetLogs';
import LogTable from './LogTable';
import API from 'api';



const useStyles = makeStyles((theme) => ({

}));

const LogPage = () => {
    const { showSnackbar, showAlert } = useContext(AppContext);
    
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();
    const [ division, setDivision ] = useState();
    const [ code, setCode ] = useState();

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useGetAppLogs(page, trigger, division, code, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    const [ checked, setChecked ] = useState([]);
    const handleRemoveSelected = () => {
        if (checked.length === 0) return showAlert('하나 이상 선택해주세요.');
        
        const ids = checked.join(',');
		API.delete(`/admin/app-logs?ids=${ids}`)
		.then(() => {
			showSnackbar('삭제했습니다.');
            handleRefresh();
		});
    }

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>로그</PageTitle>

                {!loading ? <>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            select
                            style={{ width: '200px' }}
                            size='small'
                            variant="outlined"
                            label={'구분'}
                            onChange={event => setDivision(event.target.value)}
                            value={division || ''}
                        >
                            <MenuItem value={'error'}>에러</MenuItem>
                            <MenuItem value={'info'}>정보</MenuItem>
                        </TextField>
                        <TextField
                            variant="outlined"
                            style={{ width: '200px', marginLeft: '8px' }}
                            size='small'
                            label={'코드'}
                            onChange={event => setCode(event.target.value)}
                            value={code}
                        />
                        <Button variant="contained" style={{ marginLeft: 'auto' }} color="primary" onClick={handleRemoveSelected}>선택 삭제</Button>
                    </div>
                    <div style={{ marginTop: '8px' }}>
                        <LogTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage}
                            checked={checked}
                            setChecked={setChecked}
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>
        </div>
	);
}

export default LogPage;