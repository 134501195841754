import React, { useContext } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { AppBar, Button, makeStyles, Toolbar, Drawer } from '@material-ui/core';
import colors from '../appcolors';
import { AuthContext } from '../contexts/auth.context';
import SidebarMenus from './SidebarMenus';


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuItem: {
        fontSize: 14,
        color: colors.textNeutral,
        marginLeft: 8,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        backgroundColor: colors.pageGray,
    },
}));

function MainLayout({ children }) {
	const classes = useStyles();

    const { me, clearAuthInfo } = useContext(AuthContext);

    const handleLogout = () => {
        clearAuthInfo();
    }

	return (
		<div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar style={{ backgroundColor: colors.background, justifyContent: 'space-between' }}>
                    <Button component={RouteLink} style={{ color: colors.primary, fontWeight: 'bold', textTransform: 'lowercase', marginRight: 'auto' }} to={'/'}>mnx15</Button>
                    {me ? <Button className={classes.menuItem} onClick={handleLogout}>로그아웃</Button>
                    : <Button component={RouteLink} className={classes.menuItem} to={'/auth/signin'}>로그인</Button>}
                </Toolbar>
            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper }}
            >
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <SidebarMenus />
                </div>
            </Drawer>

            <div className={classes.content}>
                <Toolbar />

                {children}
            </div>
		</div>
	);
}

export default MainLayout;
