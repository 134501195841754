import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Foo from '../src/views/Foo';
import Page404 from '../src/components/Page404';
import WithLayout from './layouts/WithLayout';
import MainLayout from './layouts/MainLayout';
import UserListPage from './views/user/list/UserListPage';
import SigninPage from './views/auth/Signin/SigninPage';
import ItemListPage from './views/item/list/ItemListPage';
import ItemDetailPage from './views/item/detail/ItemDetailPage';
import ItemFormPage from './views/item/form/ItemFormPage';
import TicketPage from './views/ticket/TicketPage';
import OptionListPage from 'views/option/option-list/OptionListPage';
import OptionImagesPage from 'views/option/option-images/OptionImagesPage';
import OptionFormPage from 'views/option/option-form/OptionFormPage';
import SelectFormPage from 'views/option/select-form/SelectFormPage';
import ReadyBucketPage from 'views/bucket/ready/ReadyBucketPage';
import SendedBucketPage from 'views/bucket/sended/SendedBucketPage';
import ReceivedBucketPage from 'views/bucket/received/ReceivedBucketPage';
import DoneBucketPage from 'views/bucket/done/DoneBucketPage';
import GenerateBucketPage from 'views/bucket/generate/GenerateBucketPage';
import ColumnFormPage from 'views/column/form/ColumnFormPage';
import ColumnListPage from 'views/column/list/ColumnListpage';
import NotationPage from 'views/item/notation/NotationForm';
import InquiryPage from 'views/inquiry/InquiryPage';
import WaitingBucketPage from 'views/bucket/waiting/WaitingBucketPage';
import CanceledBucketPage from 'views/bucket/canceled/CanceledBucketPage';
import ReturnedBucketPage from 'views/bucket/returned/ReturnedBucketPage';
import ReplaceClaimPage from 'views/claim/replace/ReplaceClaimPage';
import ReturnClaimPage from 'views/claim/return/ReturnClaimPage';
import OrderListPage from 'views/order/list/OrderListPage';
import ReviewListPage from 'views/review/list/ReviewListPage';
import AlarmPage from 'views/alarm/send/AlarmPage';
import AlarmListPage from 'views/alarm/list/AlarmListPage';
import DashboardPage from 'views/statistics/dashboard/DashboardPage';
import { Redirect } from 'react-router-dom';
import NotifyEmailPage from 'views/statistics/notify-email/NotifyEmailPage';
import LogPage from 'views/statistics/log/LogPage';
import CafeuserListPage from 'views/user/cafeuser/CafeuserListPage';
import SmsPage from 'views/user/sms/SmsPage';
import ItemLinkPage from 'views/item/link/ItemLinkPage';
import SettingsPage from 'views/settings/SettingsPage';
import CouponListPage from 'views/coupon/CouponListPage';
import OrderTransferListPage from 'views/order/list/OrderTransferListPage';
import ReferrerPage from 'views/statistics/referrer/ReferrerPage';
import HostReferrerPage from 'views/statistics/referrer/HostReferrerPage';


const DevelopmentPage = lazy(() => import('./views/development/DevelopmentPage'));

const Routes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route path="/" exact={true}>
                    <Redirect to="/statistics/dashboard" />
                </Route>

                <Route path="/auth/signin">
                    <WithLayout component={SigninPage} layout={MainLayout} />
                </Route>

                <Route path="/user">
                    <WithLayout component={UserListPage} layout={MainLayout} />
                </Route>

                <Route path="/cafeuser">
                    <WithLayout component={CafeuserListPage} layout={MainLayout} />
                </Route>

                <Route path="/sms">
                    <WithLayout component={SmsPage} layout={MainLayout} />
                </Route>

                <Route path="/ticket">
                    <WithLayout component={TicketPage} layout={MainLayout} />
                </Route>

                <Route path="/inquiry">
                    <WithLayout component={InquiryPage} layout={MainLayout} />
                </Route>

                <Route path="/item/list">
                    <WithLayout component={ItemListPage} layout={MainLayout} />
                </Route>

                <Route path="/item/register">
                    <WithLayout component={ItemFormPage} layout={MainLayout} />
                </Route>

                <Route path="/item/form/:code">
                    <WithLayout component={ItemFormPage} layout={MainLayout} />
                </Route>

                <Route path="/item/detail/:code">
                    <WithLayout component={ItemDetailPage} layout={MainLayout} />
                </Route>

                <Route path="/item/:itemId/options">
                    <WithLayout component={OptionListPage} layout={MainLayout} />
                </Route>

                <Route path="/item/:itemCode/option-images">
                    <WithLayout component={OptionImagesPage} layout={MainLayout} />
                </Route>

                <Route path="/option/select-register/:itemId">
                    <WithLayout component={SelectFormPage} layout={MainLayout} />
                </Route>

                <Route path="/option/option-register/:selectId">
                    <WithLayout component={OptionFormPage} layout={MainLayout} />
                </Route>

                <Route path="/item/:itemId/notation">
                    <WithLayout component={NotationPage} layout={MainLayout} />
                </Route>

                <Route path="/item/:itemId/link">
                    <WithLayout component={ItemLinkPage} layout={MainLayout} />
                </Route>

                <Route path="/order/list">
                    <WithLayout component={OrderListPage} layout={MainLayout} />
                </Route>

                <Route path="/order/transfer-list">
                    <WithLayout component={OrderTransferListPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/waiting">
                    <WithLayout component={WaitingBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/ready">
                    <WithLayout component={ReadyBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/sended">
                    <WithLayout component={SendedBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/received">
                    <WithLayout component={ReceivedBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/done">
                    <WithLayout component={DoneBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/canceled">
                    <WithLayout component={CanceledBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/returned">
                    <WithLayout component={ReturnedBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/bucket/generate">
                    <WithLayout component={GenerateBucketPage} layout={MainLayout} />
                </Route>

                <Route path="/claim/return">
                    <WithLayout component={ReturnClaimPage} layout={MainLayout} />
                </Route>

                <Route path="/claim/replace">
                    <WithLayout component={ReplaceClaimPage} layout={MainLayout} />
                </Route>

                <Route path="/review/list">
                    <WithLayout component={ReviewListPage} layout={MainLayout} />
                </Route>

                <Route path="/coupon/list">
                    <WithLayout component={CouponListPage} layout={MainLayout} />
                </Route>

                <Route path="/alarm/send">
                    <WithLayout component={AlarmPage} layout={MainLayout} />
                </Route>

                <Route path="/alarm/list">
                    <WithLayout component={AlarmListPage} layout={MainLayout} />
                </Route>

                <Route path="/statistics/dashboard">
                    <WithLayout component={DashboardPage} layout={MainLayout} />
                </Route>

                <Route path="/statistics/notify-email">
                    <WithLayout component={NotifyEmailPage} layout={MainLayout} />
                </Route>

                <Route path="/statistics/referrer">
                    <WithLayout component={ReferrerPage} layout={MainLayout} />
                </Route>

                <Route path="/statistics/host-referrer">
                    <WithLayout component={HostReferrerPage} layout={MainLayout} />
                </Route>

                <Route path="/statistics/log">
                    <WithLayout component={LogPage} layout={MainLayout} />
                </Route>

                <Route path="/column/write">
                    <WithLayout component={ColumnFormPage} layout={MainLayout} />
                </Route>

                <Route path="/column/form/:id">
                    <WithLayout component={ColumnFormPage} layout={MainLayout} />
                </Route>

                <Route path="/column/list">
                    <WithLayout component={ColumnListPage} layout={MainLayout} />
                </Route>

                <Route path="/settings">
                    <WithLayout component={SettingsPage} layout={MainLayout} />
                </Route>

                <Route path="/foo"><Foo /></Route>

                <Route path="/development" component={DevelopmentPage} />

                <Route path="*" component={Page404}/>
            </Switch>
        </Suspense>
    );
}

export default Routes;