import React, { useState } from 'react';
import { TableFooter, TablePagination, Button, Checkbox, DialogContent, Dialog, DialogActions, DialogContentText, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import StyledTableCell from 'components/StyledTableCell';
import StyledTableRow from 'components/StyledTableRow';
import { datetimePipe, logDivisionPipe } from 'pipes';


const LogTable = ({ rows, totalCount, rowsInPage, page, setPage, checked, setChecked }) => {
	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

	// detail
    const [ detailModalOpen, setDetailModalOpen ] = useState(false);
    const [ detail, setDetail ] = useState();
    const handleShowDetailClick = (row) => () => {
        setDetail(row);
        setDetailModalOpen(true);
    }
    // end: detail

	// checkbox interface
	const [ allChecked, setAllChecked ] = useState(false);
	const allCheckedPress = () => {
		setAllChecked(val => !val);

		const result = [];
		if (!allChecked) {
			for (let row of rows) {
				result.push(row.id);
			}
		}
		setChecked(result);
	}

	const handleCheck = (id) => () => {
		setChecked((list) => {
			const result = [...list];
			const index = list.indexOf(id);
			if (index > -1) {
				result.splice(index, 1);
			}
			else {
				result.push(id);
			}
			return result;
		});
	}
	// end

	return (
        <TableContainer component={Paper}>
			<Table style={{ minWidth: '650px' }} size="small">
				<TableHead>
					<TableRow>
						<StyledTableCell><Checkbox checked={allChecked} onChange={allCheckedPress} style={{ backgroundColor: 'white'}} /></StyledTableCell>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
						<StyledTableCell>{'관리'}</StyledTableCell>
						<StyledTableCell>{'구분'}</StyledTableCell>
						<StyledTableCell>{'코드'}</StyledTableCell>
						<StyledTableCell>{'사용자'}</StyledTableCell>
						<StyledTableCell>{'메세지'}</StyledTableCell>
						<StyledTableCell>{'요청 URL'}</StyledTableCell>
						<StyledTableCell>{'정보'}</StyledTableCell>
						<StyledTableCell>{'생성일시'}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id}>
							<StyledTableCell><Checkbox checked={checked.indexOf(row.id) > -1} onChange={handleCheck(row.id)} /></StyledTableCell>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
							<StyledTableCell>
								<Button variant='contained' size="small" onClick={handleShowDetailClick(row)}>보기</Button>
							</StyledTableCell>
							<StyledTableCell>{logDivisionPipe(row.division)}</StyledTableCell>
							<StyledTableCell>{row.code}</StyledTableCell>
							<StyledTableCell>{row.userId}</StyledTableCell>
							<StyledTableCell>{row.message}</StyledTableCell>
							<StyledTableCell>{row.originalUrl}</StyledTableCell>
							<StyledTableCell style={{ maxWidth: '360px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{JSON.stringify(row.meta)}</StyledTableCell>
							<StyledTableCell>{datetimePipe(row.createdAt)}</StyledTableCell>
							
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={10} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={10}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>

			{detail && <Dialog
				fullScreen
                open={detailModalOpen}
                onClose={() => { setDetailModalOpen(false); }}
            >
                <DialogContent>
                    <Typography variant='h5'>스택</Typography>
					<Typography variant='body1'>{detail.stack}</Typography>
                    <Typography variant='h5' style={{ marginTop: '40px' }}>정보</Typography>
                    <Typography variant='body1'><pre>{JSON.stringify(detail.meta, null, 2)}</pre></Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDetailModalOpen(false); }} color="primary" autoFocus>확인</Button>
                </DialogActions>
            </Dialog>}
		</TableContainer>
	);
}

export default LogTable;