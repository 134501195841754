import { useEffect, useState } from "react";
import axios from "axios";
import useApi from "hooks/useApi";
import API from "api";


const useItemSearch = (keyword, category, page, setPage, orderby) => {
    const [ rows, setRows ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ rowsInPage, setRowsInPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(true);

    useEffect(() => {
        setRows([]);
        setMayMore(true);
        setPage(0);
    }, [ keyword, category, orderby ]);

    useApi(() => {
        let cancel;
        setLoading(true);
        const params = { 
            keyword, 
            category,
            page,
            orderby,
        };
        API.get('/item/items', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setTotalCount(data.totalCount);
            setRowsInPage(data.rowsInPage);
            setRows(data.rows);
            setMayMore(data.rows.length > 0);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ keyword, category, page, orderby ]);

    return { loading, rows, mayMore, totalCount, rowsInPage };
}

export default useItemSearch;