import React from 'react';
import { TableFooter, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { alarmCodeStatePipe, datetimePipe, user_pipe } from '../../../pipes';
import StyledTableCell from 'components/StyledTableCell';
import StyledTableRow from 'components/StyledTableRow';


const AlarmTable = ({ rows, totalCount, rowsInPage, page, setPage }) => {
	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

	return (
        <TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
                        <StyledTableCell align="left">{'구분'}</StyledTableCell>
						<StyledTableCell align="left">{'사용자'}</StyledTableCell>
						<StyledTableCell align="left">{'메세지'}</StyledTableCell>
                        <StyledTableCell align="left">{'알림일시'}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id}>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                            <StyledTableCell align="left">{alarmCodeStatePipe(row.code)}</StyledTableCell>
							<StyledTableCell align="left">{user_pipe(row.user)}</StyledTableCell>
                            <StyledTableCell align="left">{row.message}</StyledTableCell>
							<StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={4} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={3}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

export default AlarmTable;