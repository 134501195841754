import Axios from 'axios';


const API = Axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: process.env.NODE_ENV === 'development' ? 0 : 4000,
	withCredentials: true,		// 크로스도메인 일 경우 cookie 를 보내기 위해 true 를 해놓아야 함
});

export function setupInterceptor(history, clearAuthInfo, setLoginRedirectURL, showAlert, showDialog) {
	console.info('api interceptor setup.');

	// request interceptor
	API.interceptors.request.use(function (config) {
		console.log('API Request:', config);
		return config;
	});

	// response interceptor
	API.interceptors.response.use((response) => {
		console.log('API Response:', response);
		return response?.data;
	}, 
	(error) => {
		if (error.response) {
			console.log(error.response.data);
			
			if (error.response.status === 401) {
				const dialogData = {
					title: '사용자 인증',
					message: '인증이 필요합니다. 로그인 페이지로 이동하시겠습니까?',
					first: {
						onClick: () => {
							clearAuthInfo();
							setLoginRedirectURL(history.location.pathname);
							history.push('/auth/signin');
						}
					},
					second: true,
				}
				showDialog(dialogData);
			}
            else if (error.response.status === 404) {
				showAlert('404');
            }
			else if (error.response.status === 403) {
				showAlert('권한이 없습니다.');
			}
			else if (error.response.status === 400) {
				showAlert('잘못된 요청입니다.');
            }
            else if (error.response.status === 500) {
				const code = error.response.data?.code;
				if (code) {
					switch(code) {
						case 'jwt_timeout': 
							clearAuthInfo();
							break;
						case 'LIMIT_FILE_SIZE':
							doAlert('파일크기가 너무 큽니다.')
							break;
						default:
							const error = new Error('에러가 발생했습니다.');
							error.code = code;
							throw error;
					}
				}
				else {
					showAlert(error.response.data.message);
				}
            }
			else if (error.response.data.errors?.length > 0) {
				const i_error = error.response.data.errors[0];
				switch (i_error.code) {
					case 'yup_invalid_type':
						showAlert(`올바른 형식으로 입력해주세요. [${i_error.values.path}]`);
						break;	
					case 'yup_field_required':
						showAlert(`필수 입력값을 입력해주세요. [${i_error.values.path}]`);
						break;
					case 'yup_not_integer':
						showAlert(`정수가 아닌값이 있습니다. [${i_error.values.path}]`);
						break;
					case 'yup_not_positive':
						showAlert(`양수가 아닌값이 있습니다. [${i_error.values.path}]`);
						break;
					case 'yup_too_small':
						showAlert(`너무 작은 값을 입력하셨습니다. [${i_error.values.path}]`);
						break;
					case 'yup_too_large':
						showAlert(`너무 큰 값을 입력하셨습니다. [${i_error.values.path}]`);
						break;
					case 'yup_length':
						showAlert(`글자길이가 맞지 않습니다. [${i_error.values.path}]`);
						break;
					default: showAlert(error.response.data.message);
				}
			}
			else {
				showAlert('에러가 발생했습니다. 고객센터에 문의하여 주세요.');
			}
		}
		else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			if (error.code === 'ECONNABORTED') {
                doAlert('시간이 초과되었습니다. 다시 시도해보세요.');
            }
            else {
                console.log(error.request);
				doAlert(error.message)
            }
		} 
		else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			if (error.message) doAlert(error.message)
		}

		return Promise.reject(error);
	});
}

const doAlert = (message) => {
	alert(message);
}





export default API;