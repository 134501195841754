import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { coupon_code_pipe, datetimePipe, mobilePipe, numberFormatPipe, order_state_pipe } from '../../../pipes';
import Loading from 'components/Loading';
import API from 'api';
import userInfoStyle from 'hooks/useInfoStyle';


const OrderDetail = ({ orderId }) => {
	const classes = userInfoStyle();

	const [ order, setOrder ] = useState();
	useEffect(() => {
		const params = { orderId };
		API.get('/order/order', { params })
		.then(setOrder);
	}, []);

    if (!order) return (<Loading />);

	return (
		<Box>
			<div className={classes.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>일반정보</Typography>
				</Box>
				<div className={classes.table}>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>주문번호</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{order.id} ({order.merchantId})</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>상태</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{order_state_pipe(order.state)}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>아이엠포트 고유번호</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{order.iamportId}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>총상품 가격</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{numberFormatPipe(order.price)}원</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>배달비</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{numberFormatPipe(order.deliveryPrice)}원</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>환불된 가격</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{numberFormatPipe(order.refundAmount)}원</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>주문일시</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{datetimePipe(order.createdAt)}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>결제일시</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{datetimePipe(order.doneAt)}</Typography>
						</div>
					</div>
				</div>
			</div>

			{order.method === 'transfer' && <div className={classes.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>계좌이체 정보</Typography>
				</Box>
				<div className={classes.table}>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>계좌주</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{order.accountHolder}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>입금금액</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{numberFormatPipe(order.price + order.deliveryPrice) + ' 원'}</Typography>
						</div>
					</div>
				</div>
			</div>}

			{order.stuff && <div className={classes.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>상품정보</Typography>
				</Box>
				<div className={classes.table}>
					{order.stuff.map(item => <div key={item.id} className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>제품 / 단가 / 개수 / 옵션</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{`${item.item.name} / ${numberFormatPipe(item.eventPrice || item.price)} / ${item.count}`}{item.proxy && ` / ${item.proxy.proxyOptions.map(el => el.option.name).join(', ')}`}</Typography>
						</div>
					</div>)}
				</div>
			</div>}

			{order.discount && <div className={classes.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>할인정보</Typography>
				</Box>
				<div className={classes.table}>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>할인가격</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{`${numberFormatPipe(order.discount.amount)}원 [${order.discount.rate}%]`}</Typography>
						</div>
					</div>
				</div>
			</div>}

			<div className={classes.section}>	
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>쿠폰정보</Typography>
				</Box>
				{order.coupons.length === 0 && <Typography variant="body2" color="textSecondary">쿠폰을 사용하지 않았습니다.</Typography>}
				{order.coupons.map(coupon => <Fragment key={coupon.id}>
					<div className={classes.table}>
						<div className={classes.row}>
							<div className={classes.label}>
								<Typography variant="body2" className={classes.labelText}>쿠폰</Typography>
							</div>
							<div className={classes.content}>
								<Typography variant="body2">{`일련번호: ${coupon.id}, ` + coupon_code_pipe(coupon.code)}</Typography>
							</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>
								<Typography variant="body2" className={classes.labelText}>쿠폰 발급자</Typography>
							</div>
							<div className={classes.content}>
								<Typography variant="body2">{coupon.generator?.nickname || '-'}</Typography>
							</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>
								<Typography variant="body2" className={classes.labelText}>쿠폰 발급일시</Typography>
							</div>
							<div className={classes.content}>
								<Typography variant="body2">{datetimePipe(coupon.createdAt)}</Typography>
							</div>
						</div>
					</div>
				</Fragment>)}
			</div>

			{order.destination && <div className={classes.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>받는사람정보</Typography>
				</Box>
				<div className={classes.table}>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>주소</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{`[${order.destination.zipcode}] ${order.destination.address} ${order.destination.addressDetail}`}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>성함</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{order.destination.name}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>연락처</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{mobilePipe(order.destination.mobile)}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>이메일</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{order.destination.email}</Typography>
						</div>
					</div>
				</div>
			</div>}

			<div className={classes.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>계정정보</Typography>
				</Box>
				{order.user ? <div className={classes.table}>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>계정</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{`${order.user.nickname} [${order.user.id}]`}</Typography>
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.label}>
							<Typography variant="body2" className={classes.labelText}>이메일</Typography>
						</div>
						<div className={classes.content}>
							<Typography variant="body2">{order.user.email}</Typography>
						</div>
					</div>
				</div> : <Typography>비회원 주문</Typography>}
			</div>
		</Box>
	);
}

export default OrderDetail;