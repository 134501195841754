import React from 'react';
import { Typography } from '@material-ui/core';

import colors from '../appcolors';


const Nodata = ({children, style}) => {
	return (
		<Typography variant="h5" style={{ color: colors.textSecondary, ...style }}>{children || '데이터가 없습니다.'}</Typography>
	);
}

export default Nodata;