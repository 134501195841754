import React, { useState } from 'react';
import { Typography, makeStyles, useTheme, Button, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loading from '../../../components/Loading';
import Divider from 'components/Divider';
import colors from 'appcolors';
import useApi from 'hooks/useApi';
import API from 'api';
import Row from './SelectRow';



const useStyles = makeStyles((theme) => ({
    section: {
        padding: '16px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        borderTop: '3px solid #cccccc',
        marginTop: '8px',
    },
    row: {
        display: 'flex',
        borderBottom: '1px solid #cccccc',
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        width: '60px',
        backgroundColor: '#f0f0f0',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            width: '100px',
        },
        [theme.breakpoints.up('md')]: {
            width: '140px',
        },
    },
    labelText: {
        color: colors.textPrimary,
    },
    content: {
        padding: '8px 16px',
        flex: 1,
    },
    inputRow: {
        display: 'flex',
        marginBottom: '4px',
    },
    inputContent: {
        margin: '0 8px',
        flex: 1,
    },
    subButton: {
        marginTop: '16px',
        fontSize: '14px',
    },
}));

export default function ItemDetailPage() {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();

    const { code } = useParams();

    const [ item, setItem ] = useState();
    useApi(() => {
        API.get('/item/item/' + code)
        .then(setItem);
    }, [ code ]);

	return (
        <>
            {item ? <>
                <div className="pageMargin">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ flex: 1 }}>제품상세정보</h3>
                        <Button variant="contained" color="secondary" style={{ marginRight: '8px' }} onClick={() => { history.push(`/item/form/${code}`); }}>수정</Button>
                        <Button variant="contained" onClick={() => { history.push('/item/list'); }}>목록</Button>
                    </div>
                    
                    <Paper className="pagePadding">
                        <h4>기본정보</h4>
                        <Divider />
                        <div className={classes.table}>
                            <div className={classes.row}>
                                <div className={classes.label}>
                                    <Typography variant="body1" className={classes.labelText}>일련번호</Typography>
                                </div>
                                <div className={classes.content}>
                                    <Typography variant="body1">{item.id}</Typography>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.label}>
                                    <Typography variant="body1" className={classes.labelText}>코드</Typography>
                                </div>
                                <div className={classes.content}>
                                    <Typography variant="body1">{item.code}</Typography>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.label}>
                                    <Typography variant="body1" className={classes.labelText}>판매노출</Typography>
                                </div>
                                <div className={classes.content}>
                                    <Typography variant="body1">{item.expose ? '노출' : '미노출'}</Typography>
                                </div>
                            </div>
                        </div>
                    </Paper>
                    
                    <Paper className="pagePadding" style={{ marginTop: '16px' }}>
                        <h4>이미지</h4>
                        <Divider />
                        <div style={{ marginTop: '8px', display: 'flex', height: '200px' }}>
                            {item.fileInfos.map(fileinfo => <img key={fileinfo.id} src={fileinfo.downloadURL} style={{ objectFit: 'contain', height: '100%', marginRight: '8px' }}></img>)}
                        </div>
                    </Paper>

                    <Paper className="pagePadding" style={{ marginTop: '16px' }}>
                        <h4>옵션</h4>
                        <Divider />

                        {item.selects.length > 0 ? <TableContainer component={Paper} style={{ marginTop: '8px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">일련번호</TableCell>
                                        <TableCell align="left">이름</TableCell>
                                        <TableCell align="left">구분</TableCell>
                                        <TableCell align="left">필수선택</TableCell>
                                        <TableCell align="left">순서</TableCell>
                                        <TableCell align="left">옵션수</TableCell>
                                        <TableCell align="left">옵션</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.selects.map((row) => <Row key={row.id} row={row} />)}
                                </TableBody>
                            </Table>
                        </TableContainer> : <Typography>현재 옵션이 없습니다.</Typography>}
                    </Paper>

                    <Paper className="pagePadding" style={{ marginTop: '16px' }}>
                        <h4>컨텐츠</h4>
                        <Divider />
                        <div style={{ textAlign: 'center' }}>
                            <div dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                    </Paper>
                </div>
            </> : <Loading />}
        </>
	);
}
