import React, { useContext, useRef, useState } from 'react';
import { useTheme, Button, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Typography } from '@material-ui/core';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import OrderTable from './OrderTable';
import useOrderSearch from './useOrderSearch';
import API from 'api';
import colors from 'appcolors';
import { AppContext } from 'contexts/app.context';
import OrderDetail from '../detail/OrderDetail';


const OrderTransferListPage = () => {
	const theme = useTheme();

    const { showSnackbar, showDialog } = useContext(AppContext);
    
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();
    const [ userId, setUserId ] = useState('');
    const [ nickname, setNickname ] = useState('');
    const [ params, setParams ] = useState({ method: 'transfer', state: 'pending' });

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useOrderSearch(params, page, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    // make paid
    const handleMakePaid = (order) => {
        const dialogData = {
            title: '결제완료처리',
            message: '결제완료처리하면 해당 주문의 버킷이 생성되고, 고객에게 문자(이메일)알림을 보냅니다. 진행하시겠습니까?',
            first: {
                onClick: () => {
                    const data = {
                        orderId: order.id,
                    };
                    API.put('/order/make-order-paid', data)
                    .then(() => {
                        showSnackbar('처리했습니다.');
                        handleRefresh();
                    });
                }
            },
            second: true,
        }
        showDialog(dialogData);
    }
    // end: make paid

    // search
    const handleSearchClick = () => {
        setParams({
            state: 'pending',
            method: 'transfer',
            userId,
            nickname,
        });
        setPage(0);
    }
    // end: search

    // show buckets
    const [ bucketsModalOpen, setBucketsModalOpen ] = useState(false);
    const [ selected, setSelected ] = useState();
    const handleShowBucketsClick = (order) => {
        setSelected(order);
        setBucketsModalOpen(true);
    }
    // end: show buckets

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>주문내역 - 계좌이체</PageTitle>
                {!loading ? <>
                    <div>
                        <Typography style={{ color: colors.textSecondary }}>{'* 결제완료처리가 되지 않은 내역만 조회합니다.'}</Typography>
                        <Typography style={{ color: colors.textSecondary }}>{'* 고객에게 7일안에 결제하도록 전달했습니다.'}</Typography>
                        <Typography style={{ color: colors.textSecondary }}>{'* 14일 안에 "결제완료처리" 않으면 자동으로 주문이 영구삭제됩니다.'}</Typography>
                        <Typography style={{ color: colors.textSecondary }}>{'* 5일이 지나도 결제가 되지 않으면 고객에게 결제독촉 메세지를 전달합니다.'}</Typography>
                    </div>
                    <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                label="닉네임"
                                size='small'
                                variant="outlined"
                                onChange={(event) => { setNickname(event.target.value) }}
                                value={nickname}
                            />
                            <Button style={{ marginLeft: '8px' }} variant='contained' color="primary" onClick={handleSearchClick}>검색</Button>
                        </div>
                        
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <OrderTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage}
                            handleShowBucketsClick={handleShowBucketsClick}
                            handleMakePaid={handleMakePaid}
                            method={'transfer'}
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>


            {/* buckets modal */}
            <Dialog fullScreen open={bucketsModalOpen} onClose={() => { setBucketsModalOpen(false); }}>
                <DialogTitle>주문정보</DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: '8px', display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button variant='contained' color="primary" onClick={() => { setBucketsModalOpen(false); }}>닫기</Button>
                    </div>
                    {selected && <OrderDetail orderId={selected.id} />}
                </DialogContent>
            </Dialog>
        </div>
	);
}

export default OrderTransferListPage;