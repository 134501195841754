import React, { useState } from 'react';
import { makeStyles, useTheme, Button, Paper } from '@material-ui/core';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import useAlarmSearch from './useAlarmSearch';
import AlarmTable from './AlarmTable';



const useStyles = makeStyles((theme) => ({

}));

const AlarmListPage = () => {
	const theme = useTheme();

    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useAlarmSearch(page, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>알림내역</PageTitle>
                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <AlarmTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage} 
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>

        </div>
	);
}

export default AlarmListPage;