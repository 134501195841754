import React from 'react';
import { makeStyles, useTheme, TableFooter, TablePagination, } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';



const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
    },
}));

export default function HostReferrerTable({ rows, totalCount, rowsInPage, page, setPage }) {
	const classes = useStyles();
	const theme = useTheme();

    const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

	return (
		<>
			<TableContainer component={Paper}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{'호스트'}</StyledTableCell>
                            <StyledTableCell>{'접속수'}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, idx) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell style={{ maxWidth: '360px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{row.host}</StyledTableCell>
                                <StyledTableCell>{row.count}</StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={2} />
                            </TableRow>
                        )}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            {rowsInPage && <TablePagination
                                rowsPerPageOptions={[rowsInPage]}
                                colSpan={7}
                                count={+totalCount}
                                rowsPerPage={rowsInPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
		</>
	);
}