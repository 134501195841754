import { MemoryContext } from 'contexts/memory.context';
import { useEffect, useContext } from 'react';


// deprecated, memory context 에서 canApi 가 false 이면 랜더링을 막기때문에 앞으로 사용할 필요 없음
function useApi (callback, dependencies) {
    const { canApi } = useContext(MemoryContext);

    dependencies = dependencies || [];

    useEffect(() => {
        if (canApi) {
            const recall = callback();
            return recall;
        }
    }, [ canApi, ...dependencies ]);
}

export default useApi;