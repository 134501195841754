import React, { useState } from 'react';
import { useTheme, Button, Paper, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Loading from 'components/Loading';
import useBucketSearch from '../useBucketSearch';
import PageTitle from 'components/PageTitle';
import DoneBucketTable from './DoneBucketTable';
import OrderDetail from 'views/order/detail/OrderDetail';


export default function DoneBucketPage() {
	const theme = useTheme();
    
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();

    const { 
        rows,
        loading,
        error,
        mayMore,
        totalCount,
        rowsInPage,
    } = useBucketSearch('done', page, trigger);

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    // order info
    const [ orderModalOpen, setOrderModalOpen ] = useState(false);
    const [ orderId, setOrderId ] = useState();
    const handleOrderInfo = (orderId) => () => {
        setOrderId(orderId);
        setOrderModalOpen(true);
    }
    // end: order info

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>구매완료</PageTitle>
                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <DoneBucketTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage}
                            handleOrderInfo={handleOrderInfo}
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>

            {/* order info */}
            <Dialog fullScreen open={orderModalOpen} onClose={() => { setOrderModalOpen(false); }}>
                <DialogTitle>주문정보</DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: '8px', display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button variant='contained' color="primary" onClick={() => { setOrderModalOpen(false); }}>닫기</Button>
                    </div>
                    {orderId && <OrderDetail orderId={orderId} />}
                </DialogContent>
            </Dialog>
        </div>
	);
}
