import React, { useState } from 'react';
import { Box, TableFooter, TablePagination, Button, Checkbox, Link } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { datetimePipe, destinationAddressPipe, mobilePipe, stuffOptionPipe, user_pipe } from '../../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';
import colors from 'appcolors';


const WaitingBucketTable = ({ 
	rows, 
	totalCount, 
	rowsInPage, 
	page, 
	setPage, 
	handleReady, 
	handleCancel, 
	checked, 
	setChecked,
	handleOrderInfo,
}) => {
	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

	// checkbox interface
	const [ allChecked, setAllChecked ] = useState(false);
	const allCheckedPress = () => {
		setAllChecked(val => !val);

		const result = [];
		if (!allChecked) {
			for (let row of rows) {
				result.push(row.id);
			}
		}
		setChecked(result);
	}

	const handleCheck = (id) => () => {
		setChecked((list) => {
			const result = [...list];
			const index = list.indexOf(id);
			if (index > -1) {
				result.splice(index, 1);
			}
			else {
				result.push(id);
			}
			return result;
		});
	}
	// end

	return (
        <TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow className='content-head'>
						<StyledTableCell><Checkbox checked={allChecked} onChange={allCheckedPress} style={{ backgroundColor: 'white'}} /></StyledTableCell>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
						<StyledTableCell align="left">{'주문번호'}</StyledTableCell>
						<StyledTableCell align="left">{'사용자'}</StyledTableCell>
						<StyledTableCell align="left">{'제품'}</StyledTableCell>
						<StyledTableCell align="left">{'옵션'}</StyledTableCell>
						<StyledTableCell align="left">{'개수'}</StyledTableCell>
						<StyledTableCell align="left">{'수취인'}</StyledTableCell>
						<StyledTableCell align="left">{'연락처'}</StyledTableCell>
						<StyledTableCell align="left">{'이메일'}</StyledTableCell>
						<StyledTableCell align="left">{'배송지'}</StyledTableCell>
						<StyledTableCell align="left">{'버킷생성일시'}</StyledTableCell>
						<StyledTableCell align="left" className='content-exception'>관리</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id} className='content-body'>
							<StyledTableCell><Checkbox checked={checked.indexOf(row.id) > -1} onChange={handleCheck(row.id)} /></StyledTableCell>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
							<StyledTableCell><Link className='data-target' href="#" onClick={handleOrderInfo(row.orderId)}>{row.orderId}</Link></StyledTableCell>
							<StyledTableCell>{user_pipe(row.user)}</StyledTableCell>
							<StyledTableCell>{row.stuff.item.code}</StyledTableCell>
							<StyledTableCell>{stuffOptionPipe(row.stuff)}</StyledTableCell>
							<StyledTableCell style={{ color: row.stuff.count > 1 ? 'red' : colors.textPrimary }}>x{row.stuff.count}</StyledTableCell>
							<StyledTableCell>{row.destination.name}</StyledTableCell>
							<StyledTableCell>{mobilePipe(row.destination.mobile)}</StyledTableCell>
							<StyledTableCell>{row.destination.email}</StyledTableCell>
							<StyledTableCell>{destinationAddressPipe(row.destination)}</StyledTableCell>
							<StyledTableCell>{datetimePipe(row.createdAt)}</StyledTableCell>
							<StyledTableCell className='content-exception'>
								<Box display={'flex'}>
									<Button variant='contained' color="secondary" size="small" onClick={handleReady(row.id)}>발송준비</Button>
									<Button variant='contained' style={{ marginLeft: '4px' }} size="small" onClick={handleCancel(row.id)}>취소</Button>
								</Box>
							</StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={12} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={12}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

export default WaitingBucketTable;