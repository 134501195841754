import React from 'react';
import { Paper } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import Guide from 'components/Guide';


const SettingsPage = () => {

	return (
        <div className="pageMargin">
            <PageTitle>설정</PageTitle>
            <Paper className="pagePadding">
                <h4>서버점검중 컨트롤</h4>
                <div style={{ marginTop: '24px' }}>
                    <Guide>서버점검중을 ON 하면 사용자들은 "점검중" 페이지를 보게 됩니다. (페이지만 보게 될 뿐 API 기능이 중단되지 않음)<br></br>서버점검중을 컨트롤 하려면 개발자에게 문의하세요.</Guide>
                </div>
            </Paper>


            
        </div>
	);
}

export default SettingsPage;