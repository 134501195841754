import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Typography, makeStyles, Button, TextField, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import colors from 'appcolors';
import ItemSearch from 'views/item/search/ItemSearch';
import API from 'api';
import { proxyOptionPipe } from 'pipes';



const useStyles = makeStyles((theme) => ({

}));

const StuffGenerator = ({ formik }) => {
	const classes = useStyles();
	const history = useHistory();

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    const [ item, setItem ] = useState();
    const [ proxy, setProxy ] = useState();
    const [ count, setCount ] = useState();

    const [ proxies, setProxies ] = useState();

    useEffect(() => {
        if (item) {
            setFieldValue('item', item);
            API.get('/option/proxies/' + item.id)
            .then(setProxies);
        }
    }, [ item ]);

    useEffect(() => {
        if (proxy) setFieldValue('proxy', proxy);
    }, [ proxy ]);

    const guideText = useMemo(() => {
        if (!item) return '제품을 선택해주세요.';
        if (!proxy) return `선택된 제품은 ${item.code}입니다. 옵션을 설정하려면 선택해주세요.`;
        return `선택된 제품: ${item.code}, 선택된 옵션: ${proxyOptionPipe(proxy)}`;
    }, [ item, proxy ]) ;

    const handleInitiate = () => {
        setItem(null);
        setProxy(null);
    }
    
    

	return (
        <Box border={'1px solid #dddddd'} height={'100%'} paddingX={1} paddingY={1}>
            <Typography style={{ marginBottom: '24px', fontWeight: 'bold' }}>상품 선택</Typography>

            <Typography>{guideText}</Typography>

            <Typography variant="subtitle2" className={classes.label} style={{ marginTop: '16px' }}>제품수량*</Typography>
            <TextField
                variant="outlined"
                required={true}
                size="small"
                type="number"
                onChange={handleChange('count')}
                onBlur={handleBlur('count')}
                helperText={(touched.count && errors.count) || ' '}
                error={!!(touched.count && errors.count)}
                value={values.count}
            />
            
            <br />
            {item && <Button style={{ marginTop: '8px' }} variant='outlined' size='small' onClick={handleInitiate}>선택 초기화</Button>}

            <Divider style={{ margin: '16px 0 '}} />

            <Box>
                {!item && <>
                    <ItemSearch intend="select" onSelected={setItem} />
                </>}
                
                {!proxy && <>
                    {proxies && <>
                        {proxies.length > 0 ? <>
                            {proxies.map(row => <Box key={row.id} display='flex' alignItems={'center'} marginBottom={1}>
                                <Typography>{proxyOptionPipe(row)}</Typography>
                                <Button variant='outlined' size='small' style={{ marginLeft: '16px' }} onClick={() => { setProxy(row); }} >선택</Button>
                            </Box>)}
                        </> : <Typography style={{ color: colors.textSecondary }}>선택한 제품은 옵션이 없습니다.</Typography>}
                    </>}
                </>}

            </Box>
            
        </Box>
	);
}

export default StuffGenerator;