import React, { useState } from 'react';
import { Button, TextField, Paper, useTheme, Typography } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import useHostReferrerStatistics from 'api/statistics/useHostReferrerStatistics';
import HostReferrerTable from './HostReferrerTable';



const HostReferrerPage = () => {
	const theme = useTheme();

    const [ page, setPage ] = useState(0);
    const [ from, setFrom ] = useState();
    const [ to, setTo ] = useState();
    const [ params, setParams ] = useState({});

    const { loading, error, rows, mayMore, count, rowsInPage } = useHostReferrerStatistics(page, params);

    const handleInitClick = () => {
        from('');
        to('');
        setParams({});
        setPage(0);
    }

    const handleSearchClick = () => {
        setParams({
            from,
            to,
        });
        setPage(0);
    }

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>접속통계: 호스트</PageTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="시작일"
                        type="date"
                        style={{ marginLeft: '16px' }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => { setFrom(event.target.value) }}
                        value={from}
                    />

                    <TextField
                        label="종료일"
                        type="date"
                        style={{ marginLeft: '16px' }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => { setTo(event.target.value) }}
                        value={to}
                    />

                    <Button style={{ marginLeft: 'auto'}} variant="contained" onClick={handleInitClick}>검색 초기화</Button>
                    <Button style={{ marginLeft: '8px'}} color="primary" variant="contained" onClick={handleSearchClick}>검색</Button>
                </div>
                <Typography variant='body2' style={{ color: '#aaaaaa' }}>웹 사이트에 최초 접속경로를 비교하기 위한 목적으로, 방문횟수와는 관계없습니다. (세션유효기간: 3개월)</Typography>
                <Typography variant='body2' style={{ color: '#aaaaaa' }}>시작일, 종료일을 입력하지 않으면, 최근 3개월기준으로 통계합니다.</Typography>

                {rows && <div style={{ marginTop: theme.spacing(4) }}>
                    <HostReferrerTable
                        rows={rows} 
                        totalCount={count} 
                        rowsInPage={rowsInPage} 
                        page={page} 
                        setPage={setPage} 
                    />
                </div>}
                {loading && <div>{loading}</div>}
                {error && <div>{error}</div>}
            </Paper>
		</div>
	);
}

export default HostReferrerPage;