import React from 'react';
import { makeStyles, useTheme, TableFooter, TablePagination, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { datetimePipe, destinationAddressPipe, discountPipe, mobilePipe, numberFormatPipe } from '../../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';



const useStyles = makeStyles((theme) => ({

}));

const OrderTable = ({
	rows,
	totalCount,
	rowsInPage,
	page,
	setPage,
	handleRefundClick,
	handleShowBucketsClick,
	handleMakePaid,
	method
}) => {
	const classes = useStyles();
	const theme = useTheme();

	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

	const userNamePipe = (user) => {
		if (!user) return '비로그인';
		else {
			return `${user?.nickname} (${user?.id})`;
		}
	}

	return (
        <TableContainer component={Paper}>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
						<StyledTableCell align="left">{'사용자'}</StyledTableCell>
						<StyledTableCell align="left">{'상품가격(원)'}</StyledTableCell>
						<StyledTableCell align="left">{'배송비(원)'}</StyledTableCell>
						<StyledTableCell align="left">{'할인(원)'}</StyledTableCell>
						<StyledTableCell align="left">{'연락처'}</StyledTableCell>
						<StyledTableCell align="left">{'배송지'}</StyledTableCell>
                        {method !== 'transfer' && <StyledTableCell align="left">{'환불한 금액(원)'}</StyledTableCell>}
						<StyledTableCell align="left">{'주문일시'}</StyledTableCell>
                        {method !== 'transfer' && <StyledTableCell align="left">{'결제일시'}</StyledTableCell>}
                        <StyledTableCell align="left">{'관리'}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id}>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
							<StyledTableCell align="left">{userNamePipe(row.user)}</StyledTableCell>
							<StyledTableCell align="left">{numberFormatPipe(row.price)}</StyledTableCell>
							<StyledTableCell align="left">{numberFormatPipe(row.deliveryPrice)}</StyledTableCell>
							<StyledTableCell align="left">{numberFormatPipe(discountPipe(row.discount))}</StyledTableCell>
							<StyledTableCell align="left">{mobilePipe(row.destination.mobile)}</StyledTableCell>
							<StyledTableCell align="left">{destinationAddressPipe(row.destination)}</StyledTableCell>
                            {method !== 'transfer' && <StyledTableCell align="left">{numberFormatPipe(row.refundAmount)}</StyledTableCell>}
							<StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>
							{method !== 'transfer' && <StyledTableCell align="left">{datetimePipe(row.doneAt)}</StyledTableCell>}
                            <StyledTableCell align="left">
								<Button size="small" color="primary" variant="contained" onClick={() => { handleShowBucketsClick(row); }}>버킷보기</Button>
                                {method !== 'transfer' && row.price - row.refundAmount > 0 && <Button style={{ marginLeft: '4px' }} size="small" variant="contained" onClick={() => { handleRefundClick(row); }}>환불</Button>}
								{method === 'transfer' && <Button style={{ marginLeft: '4px' }} color="primary" size="small" variant="outlined" onClick={() => { handleMakePaid(row); }}>결제완료처리</Button>}
                            </StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={10} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={10}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

export default OrderTable;