import React, { useState, useEffect } from 'react';
import { Button, Paper } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import API from 'api';
import moment from 'moment';
import Guide from 'components/Guide';
import Loading from 'components/Loading';



const GoogleOAuthRequest = () => {
    const [ info, setInfo ] = useState();
    useEffect(() => {
        API.get('/admin/latest-google-oauth')
        .then(data => {
            setInfo(data);
        });
    }, []);

    const onUpdateAccessToken = () => {
        API.get('/admin/generate-google-oauth-url')
        .then(data => {
            const url = data.url;
            console.log(url);
            window.location.href = url;
        })
    }

    if (!info) return (<Loading />);

	return (
        <Paper className="pagePadding">
            <PageTitle>Google OAuth Token</PageTitle>

            {info ? <div style={{ marginBottom: '16px' }}>
                <div>access_token: {info.meta.access_token}</div>
                <div>refresh_token: {info.meta.refresh_token}</div>
                <div>업데이트 된 시각: {moment(info.createdAt).format('YYYY-MM-DD hh:mm')}</div>
            </div> : <div>구글 OAuth2 정보가 없습니다.</div>}
            
            <Guide>구글 refresh_token 의 유효기간은 일주일로 추정하고 있습니다. 최근 업데이트 된 후 일주일이 되기 전에 다시 업데이트를 해주세요. 아이디: (helper.mnx15@gmail.com)</Guide>

            <Button style={{ marginTop: '16px' }} variant='contained' color="primary" onClick={onUpdateAccessToken}>Update Oauth2 Access Token</Button>
        </Paper>
	);
}

export default GoogleOAuthRequest;