import React from 'react';
import { TableFooter, TablePagination, Box, Button, Link } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from '../../../components/TablePaginationAction';
import { claimStatePipe, datetimePipe, user_pipe } from '../../../pipes';
import StyledTableRow from 'components/StyledTableRow';
import StyledTableCell from 'components/StyledTableCell';



const ReplaceClaimTable = ({ 
	rows, 
	totalCount, 
	rowsInPage, 
	page, 
	setPage, 
	onRejectClick,
	onRetrievingClick,
	onRetrievedClick,
	onResendingClick,
	onDoneClick,
	handleOrderInfo,
 }) => {
	const emptyRows = rowsInPage - rows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const claimItemCodePipe = (claim) => {
        return claim.bucket?.stuff?.item?.code;
    }

	const isMembershipUser = (user) => {
		return !!user?.auths.find(auth => auth.code === 'membership');
	}

	return (
        <TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell>{'일련번호'}</StyledTableCell>
						<StyledTableCell align="left">{'버킷번호'}</StyledTableCell>
						<StyledTableCell align="left">{'주문번호'}</StyledTableCell>
                        <StyledTableCell align="left">{'상품코드'}</StyledTableCell>
						<StyledTableCell align="left">{'상태'}</StyledTableCell>
                        <StyledTableCell align="left">{'사용자'}</StyledTableCell>
						<StyledTableCell align="left">{'멤버십'}</StyledTableCell>
                        <StyledTableCell align="left">{'이유'}</StyledTableCell>
						<StyledTableCell align="left">{'요청일시'}</StyledTableCell>
                        <StyledTableCell align="left">{'관리'}</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.id}>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
							<StyledTableCell align="left">{row.bucketId}</StyledTableCell>
							<StyledTableCell><Link href="#" onClick={handleOrderInfo(row.bucket?.orderId)}>{row.bucket?.orderId}</Link></StyledTableCell>
                            <StyledTableCell align="left">{claimItemCodePipe(row)}</StyledTableCell>
							<StyledTableCell align="left">{claimStatePipe(row)}</StyledTableCell>
                            <StyledTableCell align="left">{user_pipe(row.user)}</StyledTableCell>
							<StyledTableCell align="left">{isMembershipUser(row.user) ? 'O' : 'X'}</StyledTableCell>
                            <StyledTableCell align="left">{row.reason}</StyledTableCell>
							<StyledTableCell align="left">{datetimePipe(row.createdAt)}</StyledTableCell>
                            <StyledTableCell align="left">
                                <Box display={'flex'}>
									{row.state === 'reported' && <Button variant='contained' style={{ margin: '0 4px' }} size="small" onClick={onRejectClick(row.id)}>교환거절</Button>}
									{row.state === 'reported' && <Button variant='contained' style={{ margin: '0 4px' }} size="small" color="primary" onClick={onRetrievingClick(row.id)}>수거진행중</Button>}
									{row.state === 'retrieving' && <Button variant='contained' style={{ margin: '0 4px' }} size="small" color="primary" onClick={onRetrievedClick(row.id)}>수거완료</Button>}
									{row.state === 'resending' && <Button variant='contained' style={{ margin: '0 4px' }} size="small" color="primary" onClick={onResendingClick(row.id)}>재배송하기</Button>}
									{row.state !== 'done' && row.state !== 'rejected' && <Button variant='contained' style={{ margin: '0 4px' }} size="small" color="primary" onClick={onDoneClick(row.id)}>완료처리</Button>}
								</Box>
                            </StyledTableCell>
						</StyledTableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={9} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						{rowsInPage && <TablePagination
							rowsPerPageOptions={[rowsInPage]}
							colSpan={10}
							count={+totalCount}
							rowsPerPage={rowsInPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onPageChange={handleChangePage}
							ActionsComponent={TablePaginationActions}
						/>}
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

export default ReplaceClaimTable;