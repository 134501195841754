import React from 'react';
import { Box } from '@material-ui/core';


const Divider = () => {
    return (
        <Box borderBottom={'1px solid #dddddd'} />
    );
}

export default Divider;