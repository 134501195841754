import React, { createContext, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from './app.context';
import { AuthContext } from './auth.context';
import API, { setupInterceptor } from 'api';

const MemoryContext = createContext({});

const MemoryContextProvider = ({ children }) => {

	// setup interceptor
    const history = useHistory();
    const { clearAuthInfo, setLoginRedirectURL, fetchMyinfo } = useContext(AuthContext);
	const { showAlert, showDialog } = useContext(AppContext);

    const [ canApi, setCanApi ] = useState(false);
    useEffect(() => {
		if (!canApi) {
			// 현재 개발환경에서 두번 설정되는 버그가 있음(에러가 난 후 수정 후에 저장하면 인터셉터 설정함수가 중복 설정되어 fetchMyinfo 에서 에러가 발생하는 것으로 생각됨)
			setupInterceptor(
				history, 
				clearAuthInfo, 
				setLoginRedirectURL,
				showAlert,
				showDialog,
			);
		}
        setCanApi(true);
    }, [ canApi ]);
    // end: setup interceptor

	useEffect(() => {
		if (canApi) {
			fetchMyinfo();
		}
	}, [ canApi ]);

	const [ mainInfo, setMainInfo ] = useState();
    useEffect(() => {
        API.get('/admin/admin-main')
        .then(data => {
            setMainInfo(data);
        })
    }, []);

	return (
		<MemoryContext.Provider
			value={{
				canApi,

				mainInfo,
			}}
		>
			{canApi && children}
		</MemoryContext.Provider>
	);
};

export {
	MemoryContext,
	MemoryContextProvider
};