import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import useSearchSms from './useSearchSms';
import SmsTable from './SmsTable';


const SmsList = ({ trigger }) => {
    const [page, setPage] = useState(0);
    const { 
        rows,
        totalCount,
        rowsInPage,
    } = useSearchSms(page, trigger);

	return (
        <Paper className="pagePadding">
            <PageTitle>SMS 보낸 내역</PageTitle>

            {rows && <div style={{ marginTop: '24px' }}>
                <SmsTable
                    rows={rows} 
                    totalCount={totalCount} 
                    rowsInPage={rowsInPage} 
                    page={page} 
                    setPage={setPage} 
                />
            </div>}
        </Paper>
	);
}

export default SmsList;