import React, { useState, useEffect, useContext } from 'react';
import { Typography, makeStyles, Button, TextField, Paper, Divider } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import Loading from 'components/Loading';
import colors from 'appcolors';
import API from 'api';
import { AppContext } from 'contexts/app.context';



const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        marginBottom: '8px',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
}));

const NotationPage = () => {
	const classes = useStyles();
	const history = useHistory();

    const { itemId } = useParams();

    const { showSnackbar } = useContext(AppContext);

    // load data
    const [ loading, setLoading ] = useState(false);
    const [ notation, setNotation ] = useState();
    const [ defaultNotation, setDefaultNotation ] = useState();
    useEffect(() => {
        setLoading(true);
        API.get('/notation/detail/' + itemId)
        .then(data => {
            setNotation(data.notation);
            setDefaultNotation(data.defaultNotation);
        })
        .finally(() => { setLoading(false); });
    }, [ itemId ]);
    // end: load data

    const handleNotationSubmit = (values, { setSubmitting, resetForm }) => {
        // todo: 제품의 notation 이 없을시 생성해야함
        if (!notation) {
            const data = {
                ...values,
                itemId,
            };
            API.post('/notation/notation', data)
            .then(() => {
                showSnackbar('업데이트 했습니다.');
            })
        }
        else {
            const data = {
                ...values,
                id: notation.id,
            };
            API.put('/notation/notation', data)
            .then(() => {
                showSnackbar('업데이트 했습니다.');
            })
        }
    }

    const handleDefaultNotationSubmit = (values, { setSubmitting, resetForm }) => {
        const data = {
            ...values,
            id: defaultNotation.id,
        };

        API.put('/notation/notation', data)
        .then(() => {
            showSnackbar('업데이트 했습니다.');
        })
    }

    if (loading) return <Loading />

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '8px' }}>제품필수표기정보</Typography>
                <Divider />
                <Typography variant="h6" style={{ color: colors.secondary }}>*입력하지 않을시 디폴트 제품필수표기정보의 정보가 대신 표기됩니다.</Typography>

                <Formik
                    initialValues={{
                        matter: (notation && notation.matter) || '',
                        warranty: (notation && notation.warranty) || '',
                        precautions: (notation && notation.precautions) || '',
                    }}
                    onSubmit={handleNotationSubmit}
                >
                    {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>제품의 주소재</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChange('matter')}
                                        onBlur={handleBlur('matter')}
                                        value={values.matter}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>품질보증기준</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChange('warranty')}
                                        onBlur={handleBlur('warranty')}
                                        value={values.warranty}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>취급시 주의사항</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChange('precautions')}
                                        onBlur={handleBlur('precautions')}
                                        value={values.precautions}
                                    />
                                </div>


                                <div style={{ marginTop: '24px' }}>
                                    <Button variant="contained" type="button" onClick={() => history.goBack()}>뒤로</Button>
                                    <Button type="submit" style={{ marginLeft: '8px' }} variant="contained" color="primary">업데이트</Button>
                                </div>
                            </form>
                        </>
                    )}
                </Formik>
            </Paper>

            <Paper className="pagePadding" style={{ marginTop: '24px' }}>
                <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '8px' }}>디폴트: 제품필수표기정보</Typography>
                <Divider />

                <Formik
                    initialValues={{
                        matter: (defaultNotation && defaultNotation.matter) || '',
                        warranty: (defaultNotation && defaultNotation.warranty) || '',
                        precautions: (defaultNotation && defaultNotation.precautions) || '',
                    }}
                    onSubmit={handleDefaultNotationSubmit}
                >
                    {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>제품의 주소재</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChange('matter')}
                                        onBlur={handleBlur('matter')}
                                        value={values.matter}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>품질보증기준</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChange('warranty')}
                                        onBlur={handleBlur('warranty')}
                                        value={values.warranty}
                                    />
                                </div>

                                <div className={classes.section}>
                                    <Typography variant="subtitle2" className={classes.label}>취급시 주의사항</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChange('precautions')}
                                        onBlur={handleBlur('precautions')}
                                        value={values.precautions}
                                    />
                                </div>


                                <div style={{ marginTop: '24px' }}>
                                    <Button type="submit" style={{ marginLeft: '8px' }} variant="contained" color="primary">업데이트</Button>
                                </div>
                            </form>
                        </>
                    )}
                </Formik>
            </Paper>
        </div>
	);
}

export default NotationPage;